import React, { useState } from "react";
import "./StudyInNewZealandAccrodian.css";
import StudyInNewZilandAccrodianBody from "./StudyInNewZealandAccrodianBody";

const StudyInNewZilandAccrodian = () => {
  const [collapse1, setCollapse1] = useState(true);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [collapse4, setCollapse4] = useState(false);
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <h2
        id="accordion-collapse-heading-1"
        onClick={() => {
          const t = collapse1;
          setCollapse1(!collapse1);
          setCollapse2(false);
          setCollapse3(false);
          setCollapse4(false);
          if (t) {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              el.style.height = "0px";
              el.style.transition = "height 0.5s ease-in-out";
            }
          } else {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "1190px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "1380px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "2200px";
                el.style.transition = "height 0.5s ease-in-out";
              } else {
                el.style.height = "1190px";
                el.style.transition = "height 0.5s ease-in-out";
              }
            }
            // 2
            const el2 = document?.getElementById("accordion-collapse-body-2");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
            // 3
            const el3 = document?.getElementById("accordion-collapse-body-3");
            if (el3) {
              el3.style.height = "0px";
              el3.style.transition = "height 0.5s ease-in-out";
            }
            // 4
            const el4 = document?.getElementById("accordion-collapse-body-4");
            if (el4) {
              el4.style.height = "0px";
              el4.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded="true"
          aria-controls="accordion-collapse-body-1"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse1 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse1 ? "circle" : "circle-inactive"}`}
            ></span>
            Why Study in New Zealand?
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-1"
        className={`${
          collapse1 ? "" : ""
        } h-[2200px] md:h-[1380px] lg:h-[1190px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-1"
      >
        <StudyInNewZilandAccrodianBody>
          <p className="my-2 text-gray-500 dark:text-gray-400">
            Studying in New Zealand can be an appealing option for international
            students for several reasons:
          </p>
          <div className="bg-blue">
            <h5 className="accrodian-body-span my-6">
              High-Quality Education:
            </h5>
            <p className="text-gray-500 mb-2">
              New Zealand is known for its excellent education system. The
              country has a strong commitment to education quality and is home
              to several top-ranked universities and institutions. New Zealand
              consistently ranks well in global education rankings.
            </p>
          </div>
          <h5 className="accrodian-body-span">Diverse Range of Courses:</h5>
          <p className="text-gray-500 mb-2">
            New Zealand offers a wide variety of programs and courses, covering
            numerous fields of study. Whether you're interested in science,
            technology, business, arts, or any other discipline, you're likely
            to find a program that suits your interests and career goals.
          </p>
          <h5 className="accrodian-body-span">Research Opportunities: </h5>
          <p className="text-gray-500 mb-2">
            New Zealand universities are involved in cutting-edge research
            across various fields. If you're keen on research, there are
            opportunities to engage in research projects and gain valuable
            experience.
          </p>
          <h5 className="accrodian-body-span">
            Safe and Welcoming Environment:{" "}
          </h5>
          <p className="text-gray-500 mb-2">
            New Zealand is renowned for its safe and welcoming atmosphere. It
            consistently ranks as one of the safest countries in the world,
            making it an ideal destination for international students.
          </p>
          <h5 className="accrodian-body-span">
            Beautiful Natural Environment:{" "}
          </h5>
          <p className="text-gray-500 mb-2">
            New Zealand's stunning landscapes, including mountains, beaches,
            forests, and lakes, provide an incredible backdrop for your studies.
            Outdoor enthusiasts can enjoy a wide range of recreational
            activities, such as hiking, skiing, and water sports.
          </p>
          <h5 className="accrodian-body-span">Work Opportunities: </h5>
          <p className="text-gray-500 mb-2">
            International students in New Zealand often have opportunities to
            work part-time during their studies and full-time during scheduled
            breaks. After graduation, there are also post-study work options to
            gain valuable work experience.
          </p>
          <h5 className="accrodian-body-span">English Language Environment:</h5>
          <p className="text-gray-500 mb-2">
            English is the primary language of instruction in New Zealand
            universities. This immersive English-speaking environment is
            beneficial for international students looking to improve their
            language skills.
          </p>
          <h5 className="accrodian-body-span">
            Innovative and Entrepreneurial Culture:{" "}
          </h5>
          <p className="text-gray-500 mb-2">
            New Zealand has a growing reputation for innovation and
            entrepreneurship, particularly in fields like technology and
            biotechnology. This can be appealing for students interested in
            these sectors.
          </p>
          <h5 className="accrodian-body-span">Quality of Life:</h5>
          <p className="text-gray-500 mb-2">
            New Zealand consistently ranks high in terms of quality of life,
            healthcare, and overall well-being. It offers a high standard of
            living and an excellent healthcare system.
          </p>
        </StudyInNewZilandAccrodianBody>
      </div>
      <h2
        id="accordion-collapse-heading-2"
        onClick={() => {
          const t = collapse2;
          setCollapse2(!collapse2);
          setCollapse1(false);
          setCollapse3(false);
          setCollapse4(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-2");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "450px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "450px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "580px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-3");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-4");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-2");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-2"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-2"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse2 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse2 ? "circle" : "circle-inactive"}`}
            ></span>
            Required Documents
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-2"
        className={`${collapse2 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-2"
      >
        <StudyInNewZilandAccrodianBody>
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
            <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
              <li className="relative left-[18px] my-2">Proof of enrolment</li>
              <li className="relative left-[18px] my-2">
                Academic Transcripts
              </li>
              <li className="relative left-[18px] my-2">
                English Language Proficiency Test Scores
              </li>
              <li className="relative left-[18px] my-2">
                Statement of Purpose (SOP) or Personal Statement
              </li>
              <li className="relative left-[18px] my-2">
                Curriculum Vitae (CV) or Resume
              </li>
              <li className="relative left-[18px] my-2">
                Letters of Recommendation
              </li>
              <li className="relative left-[18px] my-2">Valid Passport</li>
              <li className="relative left-[18px] my-2">
                Visa Application form and fee
              </li>
              <li className="relative left-[18px] my-2">
                Health and Travel Insurance
              </li>
              <li className="relative left-[18px] my-2">Financial Documents</li>
              <li className="relative left-[18px] my-2">
                Genuine Intent Statement
              </li>
              <li className="relative left-[18px] my-2">
                Medical and Police Clearance Certificates
              </li>
            </ul>
          </div>
        </StudyInNewZilandAccrodianBody>
      </div>
      <h2
        id="accordion-collapse-heading-3"
        onClick={() => {
          const t = collapse3;
          setCollapse3(!collapse3);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse4(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-3");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "650px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "670px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "1100px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-4");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-3");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-3"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-3"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse3 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse3 ? "circle" : "circle-inactive"}`}
            ></span>
            Application Procedures
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-3"
        className={`${collapse3 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-3"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <h5 className="accrodian-body-span">Intakes</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            The two intakes that are typically offered in New Zealand are in
            February and July, with a select few universities providing extra
            intakes in September and November. Application submission is advised
            to begin ideally 7-8 months before the admission. The application
            processing time for universities is typically 4-6 weeks.
          </p>
          <h5 className="accrodian-body-span">English Language Requirements</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            English Language requirements vary between institutions. Usually for
            undergraduate courses IELTS 6.0 and for postgraduate courses IELTS
            6.5 is required.
          </p>
          <h5 className="accrodian-body-span">Academic Requirement</h5>
          <h5 className="mb-2 font-semibold">Undergraduate – Bachelors</h5>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">
              A good academic record in High School Certificate or 12 years of
              schooling
            </li>
            <li className="relative left-[18px] my-2">
              A good academic record in High School Certificate or 12 years of
              schooling
            </li>
          </ul>
          <h5 className="my-2 font-semibold">Postgraduate – Masters</h5>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">
              A good academic record in Bachelors Degree from a recognized
              university is necessary
            </li>
            <li className="relative left-[18px] my-2">
              Any work experience in the field will be helpful
            </li>
            <li className="relative left-[18px] my-2">
              For MBA programs at some Universities, students need to have work
              experience along with their Bachelor's Degree
            </li>
          </ul>
        </div>
      </div>
      <h2
        id="accordion-collapse-heading-4"
        onClick={() => {
          const t = collapse4;
          setCollapse4(!collapse4);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse3(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-4");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "490px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "490px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "1000px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-3");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-4");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-4"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-4"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse4 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse4 ? "circle" : "circle-inactive"}`}
            ></span>
            Visa Procedure
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-4"
        className={`${collapse4 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-4"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            The visa procedure for studying in New Zealand involves several
            steps, and it's important to follow them carefully to ensure a
            smooth application process.
          </p>
          <p className="my-2 text-gray-500 dark:text-gray-400">
            Gather the necessary documents for your visa application. Pay the
            student visa application fee, which can be done online or at a New
            Zealand visa application center in your home country. Submit your
            visa application online through the Immigration New Zealand website.
            If applying offline, complete the paper application form and submit
            it to the nearest New Zealand Visa Application Center (VAC) or
            Immigration New Zealand office in your home country.
          </p>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Some applicants may be required to provide biometric information,
            such as fingerprints and a photograph, at a designated application
            center.
          </p>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            In some cases, New Zealand Immigration may request an interview as
            part of the visa application process.
          </p>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            If you applied through a New Zealand Visa Application Center (VAC),
            you can collect your visa from there. If you apply online, you may
            receive an eVisa, which you can print.
          </p>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            It's important to visit the official website of New Zealand
            Immigration for the most recent information and instructions on the
            student visa application process because visa rules and processes
            are subject to change. Consider applying for your student visa well
            enough in advance to allow for processing before the start of your
            study.
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudyInNewZilandAccrodian;
