import React from "react";
import SectionHeader from "../SectionHeader/SectionHeader";
import ChooseUsCard from "../ChooseUsCard/ChooseUsCard";
import { TbBulb, TbUserExclamation } from "react-icons/tb";
import { IoThumbsUpOutline } from "react-icons/io5";
import { HiOutlineDesktopComputer, HiOutlineUserGroup } from "react-icons/hi";
import { SlBadge } from "react-icons/sl";

const ChooseUs = () => {
  return (
    <div className="px-6 md:px-12 lg:px-32 py-12 bg-[#F4F4F4]">
      <SectionHeader
        title={"Why Choose Us?"}
        description={
          "In confusion, many can't decide where to get proper knowing for his or her desired aim and sometimes it can be an unwise decision."
        }
      />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-12 gap-4">
        <ChooseUsCard
          title={"EXPERIENCE"}
          details={
            "Experience - all that matters! Having more than 2+ years of experience in education consultancy, Russell Global Consultancy..."
          }
        >
          <TbUserExclamation />
        </ChooseUsCard>
        <ChooseUsCard
          title={"TRANSPARENCY"}
          details={
            "Straight Talk is Good Business! Our services are our commitments to you and we deliver what we commit. We do not chase the..."
          }
        >
          <IoThumbsUpOutline />
        </ChooseUsCard>
        <ChooseUsCard
          title={"PASTORAL CARE"}
          details={
            "We look at the consultancy differently! We are highly clinical to take care of your problems and remain focused until we can..."
          }
        >
          <HiOutlineUserGroup />
        </ChooseUsCard>
        <ChooseUsCard
          title={"AUTHORIZED AGENT"}
          details={
            "Our excellent network with the Universities helps us to deliver superlative services to our students. We provide end to end..."
          }
        >
          <SlBadge />
        </ChooseUsCard>
        <ChooseUsCard
          title={"INTELLIGENCY AND SKILL"}
          details={
            "Practical wisdom, trusted advice! Our success rate with visa applications and university admissions is one of the highest in..."
          }
        >
          <TbBulb />
        </ChooseUsCard>
        <ChooseUsCard
          title={"LONG TERM RELATIONSHIP"}
          details={
            "Business is our signature! At Russell Global Consultancy, we are committed to provide much more than excellent education consultancy – with us..."
          }
        >
          <HiOutlineDesktopComputer />
        </ChooseUsCard>
      </div>
    </div>
  );
};

export default ChooseUs;
