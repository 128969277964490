import React, { useState } from "react";
import ContactModal from "../../Common/ContactModal/ContactModal";

const BannerBottom = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <section className="bg-[#002147] relative top-[-8px]">
        <div className="flex flex-col lg:flex-row pt-[1rem] pb-[1rem] px-4 mx-auto max-w-screen-xl text-center lg:pb-[2.5rem] lg:pt-[2.4rem]">
          <div className="lg:w-[70%] w-full">
            <h1 className="mb-4 text-4xl w-full text-start font-extrabold tracking-tight leading-none text-white">
              Get a free consultation
            </h1>
            <p className="mb-8 text-start w-full text-md font-normal text-gray-500 dark:text-gray-400">
              Book an appointment with our experieneced consultanats to discuss
              your study abroad options.
            </p>
          </div>
          <div className="flex justify-center items-center lg:w-[30%] w-full">
            <button
              onClick={() => setShowModal(!showModal)}
              data-modal-target="authentication-modal"
              data-modal-toggle="authentication-modal"
              className="inline-flex justify-center capitalize items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
            >
              Book an apointment
              <svg
                className="w-3.5 h-3.5 ml-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
            <ContactModal showModal={showModal} setShowModal={setShowModal} />
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerBottom;
