import React from "react";
import { AiTwotoneMail } from "react-icons/ai";
import { BiSolidPhoneCall } from "react-icons/bi";
import CustomIcon from "../../Common/CustomIcon/CustomIcon";

const CeoMessageAccrodian = () => {
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <h5 className="font-semibold text-2xl mt-4 mb-2">Message from the CEO</h5>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        Welcome to our website. I hope you find the information here relevant
        and useful to you.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        Our idea is to start changing how the Bangladeshi educational system is
        acknowledged right away. The majority of Bangladeshis believe that
        obtaining a bachelor's degree is the only need for becoming an engineer,
        a banker, or a doctor. In this situation, vocational education may be
        quite beneficial. We are intimately familiar with the way in which
        Bangladeshi villagers think. Most of them are pleased to see their
        children start careers in government employment. However, there are
        still many professions available outside those in government. Being
        hired by a private farm, getting a decent job there, or starting your
        own business is neither irresponsible nor disrespectful. Since there are
        few opportunities in Bangladesh to pursue vocational education and
        receive professional training.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        We provide comprehensive and distinctive custom services as a result of
        our dedication to consistently providing the highest standards of
        service. By constantly expanding the services we provide, our
        comprehensive knowledge and skills have enabled us to grow into the
        industry leader we are today.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        I thus extend an invitation to you to join us and make use of our
        amazing services. Contact us if you can't find the information you're
        searching for on this page; we'll listen to your needs and provide you
        with a reasonable estimate.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        I'm excited to see you as soon as possible.
      </p>

      <div className="flex flex-col my-10">
        <span className="font-bold">Mr. Russell</span>
        {/* <span className="text-gray-500">LLB (Hons), UoL (UK)</span> */}
        <span className="italic font-semibold">Chief Executive</span>
        <span className="font-semibold">Russell Global Consultancy</span>
        {/* <div className="flex items-center mb-[1px]">
          <CustomIcon className="me-2">
            <AiTwotoneMail />
          </CustomIcon>
          <span className="text-blue-700">+88017*******</span>
        </div>
        <div className="flex items-center">
          <CustomIcon className="me-2">
            <BiSolidPhoneCall />
          </CustomIcon>
          <span className="text-blue-700">xxx@xxx.com</span>
        </div> */}
      </div>
    </div>
  );
};

export default CeoMessageAccrodian;
