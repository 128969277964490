import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../../../assets/uni/cardiff-logo.png";
import img2 from "../../../assets/uni/leeds-logo.png";
import img3 from "../../../assets/uni/manitoba-logo.png";
import img4 from "../../../assets/uni/northumbria-logo.png";
import img5 from "../../../assets/uni/rru-logo.png";
import img6 from "../../../assets/uni/saskatchewan-logo.png";
import img7 from "../../../assets/uni/semo-logo.png";
import img8 from "../../../assets/uni/uca-logo.png";
import img9 from "../../../assets/uni/unb-logo.png";
import img10 from "../../../assets/uni/uwe-logo.png";
import img11 from "../../../assets/uni/wright-logo.png";

const UniSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    centerMode: true,
    centerPadding: "60px",
    pauseOnHover: false,
  };
  return (
    <div>
      <Slider {...settings}>
        <div className=" bg-[#F3F4F6] p-14">
          <img src={img1} alt="" srcSet="" />
        </div>
        <div className=" bg-[#F3F4F6] p-14">
          <img src={img2} alt="" srcSet="" />
        </div>
        <div className=" bg-[#F3F4F6] p-14">
          <img src={img3} alt="" srcSet="" />
        </div>
        <div className=" bg-[#F3F4F6] p-14">
          <img src={img4} alt="" srcSet="" />
        </div>
        <div className=" bg-[#F3F4F6] p-14">
          <img src={img5} alt="" srcSet="" />
        </div>
        <div className=" bg-[#F3F4F6] p-14">
          <img src={img6} alt="" srcSet="" />
        </div>
        <div className=" bg-[#F3F4F6] p-14">
          <img src={img7} alt="" srcSet="" />
        </div>
        <div className=" bg-[#F3F4F6] p-14">
          <img src={img8} alt="" srcSet="" />
        </div>
        <div className=" bg-[#F3F4F6] p-14">
          <img src={img9} alt="" srcSet="" />
        </div>
        <div className=" bg-[#F3F4F6] p-14">
          <img src={img10} alt="" srcSet="" />
        </div>
        <div className=" bg-[#F3F4F6] p-14">
          <img src={img11} alt="" srcSet="" />
        </div>
      </Slider>
    </div>
  );
};

export default UniSlider;
