import React from "react";
import PageLayout from "../../components/Common/PageLayout/PageLayout";
import linksList from "../../utils/linksList";
import TermsAndConditionAccrodian from "../../components/Others/TermsAndConditionAccrodian";
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
  return (
    <div>
      <Helmet>
        <title>Russell Global Consultancy | Terms and Conditions</title>
        <meta
          name="description"
          content="Russell Global Consultancy Terms and Conditions Page"
        />
        <link
          rel="canonical"
          href="https://russellglobalconsultancy.com/terms-conditions"
        />
      </Helmet>
      <PageLayout
        header={"TERMS & CONDITIONS"}
        title={"Terms and Conditions"}
        subTitle={
          "Welcome to Russell Global Consultancy's Terms and Conditions. By using our website and services, you agree to comply with these terms."
        }
        links={linksList}
        layoutClass={"privacy"}
      >
        <TermsAndConditionAccrodian />
      </PageLayout>
    </div>
  );
};

export default TermsAndConditions;
