import React from "react";

const OurMissionAccrodian = () => {
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <h5 className="font-semibold text-2xl mt-4 mb-2">Our Mission</h5>
      <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
        <li className="relative left-[18px] my-2 break-words">
          To enable students to achieve their dreams and reach their potential
          in whatever they aim to do.
        </li>
        <li className="relative left-[18px] my-2 break-words">
          To provide unparalleled academic excellence.
        </li>
        <li className="relative left-[18px] my-2 break-words">
          To promote ethical practices and the development of social awareness
          and responsibility in all of our students.
        </li>
        <li className="relative left-[18px] my-2 break-words">
          Statement of Purpose
        </li>
        <li className="relative left-[18px] my-2 break-words">
          To encourage all students to aim high in whatever they do, and to
          impose no limits on students' ambitions.
        </li>
        <li className="relative left-[18px] my-2 break-words">
          To take pride in, praise, promote and support all that is excellent in
          our students.
        </li>
        <li className="relative left-[18px] my-2 break-words">
          To work with the high-esteemed hallmark of honesty, courage, fair play
          and initiative-taking along with the teamwork of our people and our
          ability to respond effectively to the positive and productive
        </li>
        <li className="relative left-[18px] my-2 break-words">
          To provide loving care, support and encouragement equally to students
          of all backgrounds and nationalities, all races and religions,
          creating within the academy a harmonious multicultural learning
          environment in which all students are equally important, happy and
          successful.
        </li>
        <li className="relative left-[18px] my-2 break-words">
          To create a work environment, which motivates, recognize and rewards
          achievements at all levels, under the slogan: ′we would always perform
          our obligations with integrity, devotion, truthfulness and confidence
          and would strive for the greatest and achieve for the highest to meet
          our sublime aims and splendid goals.′
        </li>
      </ul>
      <h5 className="font-semibold text-2xl mt-4 mb-2">Our Vision</h5>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        Russell Global Consultancy would like to be among the many other
        organizations, schools, foundations or companies that would like to
        encourage students to pause and think where and how we are going. To
        lead the change in our approach to growth & development, to take the
        responsibility for such change, that we want to see in the world around
        us. Our vision is to identify, encourage and nurture Leadership,
        Confidence, Responsibility, Teamwork, a World Vision and other positive
        traits in students and young learners through innovative technology and
        learning methodology.
      </p>
    </div>
  );
};

export default OurMissionAccrodian;
