import React, { useContext, useRef } from "react";
import useClickOutside from "../../../hooks/clickOutside";
import "./Header.css";
import { menuContext } from "../../../App";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo2.png";

const Header = () => {
  // const [sticky, setSticky] = useState(false);
  const {
    showDrop,
    setShowDrop,
    showNav,
    setShowNav,
    setShowDropSchoolership,
    showDropSchoolership,
    setShowDropUni,
    showDropUni,
  } = useContext(menuContext);
  const handleNav = () => {
    setShowNav(!showNav);
    setShowDrop(false);
    setShowDropSchoolership(false);
    setShowDropUni(false);
  };

  const dropElementRef = useRef(null);
  const dropScholaerElementRef = useRef(null);
  const dropUniElementRef = useRef(null);

  useClickOutside(dropElementRef, () => {
    setShowDrop(false);
  });
  useClickOutside(dropScholaerElementRef, () => {
    setShowDropSchoolership(false);
  });
  useClickOutside(dropUniElementRef, () => {
    setShowDropUni(false);
  });
  // const addStickyNav = () => {
  //   if (window.pageYOffset > 100) {
  //     setSticky(true);
  //     return;
  //   } else {
  //     setSticky(false);
  //     return;
  //   }
  // };

  // window.addEventListener("scroll", addStickyNav);

  return (
    <>
      <nav
        className={`bg-white border-gray-200 fixed top-0 left-0 right-0 z-[5000] transition-all ease-in-out dela-[0.3s] dark:bg-gray-900 dark:border-gray-700 shadow-lg`}
      >
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto lg:p-4 px-2 py-4">
          <Link to="/" className="flex items-center">
            <img
              src={logo}
              className="h-12 md:h-16 w-full mr-3 rounded-full"
              alt="Logo"
            />
            {/* <div className="flex flex-col justify-center items-start">
              <span className="font-medium">Russell</span>
              <span className="self-center text-lg md:text-2xl font-bold whitespace-nowrap text-[#002147]">
                Global Consultancy
              </span>
            </div> */}
          </Link>
          <button
            data-collapse-toggle="navbar-dropdown"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-dropdown"
            aria-expanded="false"
            onClick={handleNav}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`${
              showNav ? "h-auto opacity-100 absolute" : "h-0 opacity-0 hidden"
            } w-2/3 lg:block lg:h-auto lg:w-auto lg:opacity-100 transition-all duration-300 top-12 right-0 z-10`}
            id="navbar-dropdown"
          >
            <ul className="flex flex-col font-medium p-0 md:p-4 lg:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 lg:flex-row lg:space-x-8 lg:mt-0 lg:border-0 lg:bg-white dark:bg-gray-800 lg:dark:bg-gray-900 dark:border-gray-700">
              <li
                className="ani-nav-link mb-4 lg:mb-0"
                onClick={() => {
                  setShowNav(false);
                }}
              >
                <Link
                  to="/"
                  className="block py-2 pl-3 pr-4 text-gray-900 hover:text-[#ff683f] bg-transparent md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent"
                  aria-current="page"
                >
                  HOME
                </Link>
              </li>
              {/* drop down Overview  */}
              <li className="ani-nav-drop mb-4 lg:mb-0" ref={dropUniElementRef}>
                <button
                  id="dropdownNavbarLink"
                  data-dropdown-toggle="dropdownNavbar"
                  className="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 hover:text-[#ff683f] md:p-0 md:w-auto dark:text-white dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
                  onClick={() => setShowDropUni(!showDropUni)}
                >
                  OVERVIEW
                  <svg
                    className="w-2.5 h-2.5 ml-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                  {/* <!-- Dropdown menu --> */}
                  <div
                    id="dropdownNavbar"
                    className={`z-10 ${
                      showDropUni
                        ? "h-auto opacity-100"
                        : "h-0 opacity-0 hidden"
                    } transition-all duration-300 delay-500 w-[210px] absolute top-[30px] font-normal bg-[#002147] divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600`}
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-400"
                      aria-labelledby="dropdownLargeButton"
                    >
                      <li onClick={() => setShowNav(false)}>
                        <Link
                          to="/overview/about-us"
                          className="block text-[1rem] font-semibold text-white hover:text-gray-800 capitalize px-4 text-start py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          About Us
                        </Link>
                      </li>
                      <li onClick={() => setShowNav(false)}>
                        <Link
                          to="/overview/our-services"
                          className="block text-[1rem] font-semibold text-white hover:text-gray-800 px-4 text-start py-2 capitalize hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Our Services
                        </Link>
                      </li>
                      <li onClick={() => setShowNav(false)}>
                        <Link
                          to="/overview/mission"
                          className="block text-[1rem] font-semibold text-white hover:text-gray-800 px-4 text-start py-2 capitalize hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Our Mission & Vision
                        </Link>
                      </li>
                      <li onClick={() => setShowNav(false)}>
                        <Link
                          to="/overview/ceo-message"
                          className="block text-[1rem] font-semibold text-white hover:text-gray-800 px-4 text-start py-2 capitalize hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Message From CEO
                        </Link>
                      </li>
                    </ul>
                  </div>
                </button>
              </li>
              {/* Drop down Study abrod  */}
              <li className="ani-nav-drop mb-4 lg:mb-0" ref={dropElementRef}>
                <button
                  id="dropdownNavbarLink"
                  data-dropdown-toggle="dropdownNavbar"
                  className="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 hover:text-[#ff683f] md:p-0 md:w-auto dark:text-white dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
                  onClick={() => setShowDrop(!showDrop)}
                >
                  STUDY ABROAD SERVICES
                  <svg
                    className="w-2.5 h-2.5 ml-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                  {/* <!-- Dropdown menu --> */}
                  <div
                    id="dropdownNavbar"
                    className={`z-10 ${
                      showDrop ? "h-auto opacity-100" : "h-0 opacity-0 hidden"
                    } transition-all duration-300 delay-500 absolute top-[30px] font-normal bg-[#002147] divide-y divide-gray-100 rounded-lg shadow w-[210px] dark:bg-gray-700 dark:divide-gray-600`}
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-400"
                      aria-labelledby="dropdownLargeButton"
                    >
                      <li onClick={() => setShowNav(false)}>
                        <Link
                          to="/services/study-australia"
                          className="block text-[1rem] font-semibold text-white hover:text-gray-800 px-4 py-2 text-start capitalize hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Study in Australia
                        </Link>
                      </li>
                      <li onClick={() => setShowNav(false)}>
                        <Link
                          to="/services/study-newzealand"
                          className="block text-[1rem] font-semibold text-white hover:text-gray-800 px-4 py-2 text-start capitalize hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Study in New Zealand
                        </Link>
                      </li>
                      <li onClick={() => setShowNav(false)}>
                        <Link
                          to="/services/study-uk"
                          className="block text-[1rem] font-semibold text-white hover:text-gray-800 px-4 py-2 text-start capitalize hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Study in UK
                        </Link>
                      </li>
                      <li onClick={() => setShowNav(false)}>
                        <Link
                          to="/services/study-europe"
                          className="block text-[1rem] font-semibold text-white hover:text-gray-800 px-4 py-2 text-start capitalize hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Study in Europe
                        </Link>
                      </li>
                      <li onClick={() => setShowNav(false)}>
                        <Link
                          to="/services/study-china"
                          className="block text-[1rem] font-semibold text-white hover:text-gray-800 px-4 py-2 text-start capitalize hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Study in China
                        </Link>
                      </li>

                      <li onClick={() => setShowNav(false)}>
                        <Link
                          to="/services/study-scholarship-assistance"
                          className="block text-[1rem] font-semibold text-white hover:text-gray-800 px-4 py-2 text-start capitalize hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Scholarship assistance
                        </Link>
                      </li>
                    </ul>
                  </div>
                </button>
              </li>
              {/* Dropdown menu Recruitment SERVICE  */}
              <li
                className="ani-nav-drop mb-4 lg:mb-0"
                ref={dropScholaerElementRef}
              >
                <button
                  id="dropdownNavbarLink"
                  data-dropdown-toggle="dropdownNavbar"
                  className="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 hover:text-[#ff683f] md:p-0 md:w-auto dark:text-white dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
                  onClick={() => setShowDropSchoolership(!showDropSchoolership)}
                >
                  RECRUITMENT SERVICES
                  <svg
                    className="w-2.5 h-2.5 ml-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                  {/* <!-- Dropdown menu --> */}
                  <div
                    id="dropdownNavbar"
                    className={`z-10 ${
                      showDropSchoolership
                        ? "h-auto opacity-100"
                        : "h-0 opacity-0 hidden"
                    } transition-all duration-300 delay-500 absolute top-[30px] font-normal bg-[#002147] divide-y divide-gray-100 rounded-lg shadow w-[210px] dark:bg-gray-700 dark:divide-gray-600`}
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-400"
                      aria-labelledby="dropdownLargeButton"
                    >
                      <li onClick={() => setShowNav(false)}>
                        <Link
                          to="/services/recruitment-professions"
                          className="block text-[1rem] font-semibold text-white hover:text-gray-800 px-4 py-2 text-start capitalize hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Professions
                        </Link>
                      </li>
                      <li onClick={() => setShowNav(false)}>
                        <Link
                          to="/services/recruitment-services"
                          className="block text-[1rem] font-semibold text-white hover:text-gray-800 px-4 py-2 text-start capitalize hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Services
                        </Link>
                      </li>
                      <li onClick={() => setShowNav(false)}>
                        <Link
                          to="/services/recruitment-inquiry"
                          className="block text-[1rem] font-semibold text-white hover:text-gray-800 px-4 py-2 text-start capitalize hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Recruitment Inquiry
                        </Link>
                      </li>
                    </ul>
                  </div>
                </button>
              </li>
              <li
                className="ani-nav-link mb-4 lg:mb-0"
                onClick={() => setShowNav(false)}
              >
                <Link
                  to="/contact"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 hover:text-[#ff683f] md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
