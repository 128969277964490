import React, { useState } from "react";
import CustomIcon from "../../Common/CustomIcon/CustomIcon";
import { Link } from "react-router-dom";

const ChooseUsCard = ({ children, title, details }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <>
      <Link
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        to="/"
        className="hover:bg-[#193659] duration-500 border border-gray-200 rounded-lg shadow cursor-pointer block max-w-sm p-6 bg-transparent"
      >
        <div className="box-container">
          <div className="box-header flex justify-between items-center mb-3">
            <div className="icon-box w-[20%] m-auto">
              <CustomIcon
                className={`secondary-card-icon ${
                  hovered ? "secondary-card-icon-active" : ""
                }`}
              >
                {children}
              </CustomIcon>
            </div>
            <div className="box-title w-[80%]">
              <h5
                className={`mb-2 text-xl text-start capitalize font-bold tracking-tight ${
                  hovered ? "text-[#fff]" : "text-gray-900"
                }`}
              >
                {title}
              </h5>
            </div>
          </div>
          <div className="box-details w-full px-1">
            <p
              className={`font-normal ${
                hovered ? "text-[#fff]" : "text-gray-700"
              }`}
            >
              {details}
            </p>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ChooseUsCard;
