import React from "react";
import SectionHeader from "../../HomePageComponents/SectionHeader/SectionHeader";
import QuickLinks from "../QuickLinks/QuickLinks";
import "./PageLayout.css";

const PageLayout = ({
  header,
  subHeader,
  title,
  subTitle,
  children,
  links,
  layoutClass,
}) => {
  return (
    <div className="min-h-full">
      <div className={`layout ${layoutClass}`}>
        {/* layout header  */}
        <div className="layout-header-container">
          <h2 className="text-white text-3xl font-bold text-center pb-4 relative">
            {header}
          </h2>
          <span className="font-semibold text-center inline-block">
            {subHeader}
          </span>
        </div>
      </div>
      {/* layout body  */}
      <div className="py-16">
        <SectionHeader title={title} description={subTitle} />
        {/* main content  */}
        <div className="main-content px-6 md:px-12 lg:px-32 pt-20">
          {/* quick links  */}
          <div>
            <QuickLinks links={links} />
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
