import React from "react";
import "./PageLayout.css";
import CeoProfile from "../CeoProfile/CeoProfile";

const CeoPageLayout = ({ header, subHeader, layoutClass, children }) => {
  return (
    <div className="min-h-full">
      <div className={`layout ${layoutClass}`}>
        {/* layout header  */}
        <div className="layout-header-container">
          <h2 className="text-white text-3xl font-bold text-center pb-4 relative">
            {header}
          </h2>
          <span className="font-semibold text-center inline-block">
            {subHeader}
          </span>
        </div>
      </div>
      {/* layout body  */}
      <div className="py-16">
        {/* main content  */}
        <div className="main-content px-2 md:px-12 lg:px-32 pt-20">
          {/* quick links  */}
          <div>
            <CeoProfile />
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CeoPageLayout;
