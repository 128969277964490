import React from "react";
import CeoPageLayout from "../../components/Common/PageLayout/CeoPageLayout";
import CeoMessageAccrodian from "../../components/Overviews/CeoMessageAccrodian/CeoMessageAccrodian";
import { Helmet } from "react-helmet";

const CeoMessage = () => {
  return (
    <div>
      <Helmet>
        <title>Russell Global Consultancy | CEO Message</title>
        <meta
          name="description"
          content="Russell Global Consultancy CEO Message Page"
        />
        <link
          rel="canonical"
          href="https://russellglobalconsultancy.com/overview/about-us"
        />
      </Helmet>
      <CeoPageLayout
        header={"MESSAGE FROM THE CEO"}
        subHeader={"What makes us different, makes us better."}
        layoutclassName="ceo-message"
      >
        <CeoMessageAccrodian />
      </CeoPageLayout>
    </div>
  );
};

export default CeoMessage;
