const linksList = [
  {
    title: "Study in AUSTRALIA",
    target: "/services/study-australia",
  },
  {
    title: "Study in New Zealand",
    target: "/services/study-newzealand",
  },
  {
    title: "Study in UK",
    target: "/services/study-uk",
  },
  {
    title: "Study in Europe",
    target: "/services/study-europe",
  },
  {
    title: "Study in China",
    target: "/services/study-china",
  },
  {
    title: "SCHOLARSHIP ASSISTANCE",
    target: "/services/study-scholarship-assistance",
  },
  {
    title: "ABOUT US",
    target: "/overview/about-us",
  },
  {
    title: "Recruitment Professions",
    target: "/services/recruitment-professions",
  },
  {
    title: "Recruitment Services",
    target: "/services/recruitment-services",
  },
];

export default linksList;
