import React from "react";

const StudyInEuropeAccrodianBody = (props) => {
  return (
    <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
      {props.children}
    </div>
  );
};

export default StudyInEuropeAccrodianBody;
