import React from "react";
import ceoProfile from "../../../assets/images/ceo.jpg";
import CustomIcon from "../CustomIcon/CustomIcon";
import { AiFillFacebook, AiTwotoneMail } from "react-icons/ai";
import { BiSolidPhoneCall } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";

const CeoProfile = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full overflow-x-hidden p-0 md:p-5">
      <div className="w-[365px] h-[365px] overflow-hidden bg-gray-300 rounded-full flex justify-center items-center">
        <img
          src={ceoProfile}
          alt="CEO Profile"
          className="w-[350px] h-[350px] rounded-full"
        />
      </div>
      <div className="flex flex-col my-6">
        <span className="text-2xl font-bold">Mr. Russell</span>
        <span className="text-gray-500">CEO, Russell Global Consultancy</span>
      </div>
      <div className="flex">
        <CustomIcon className="mx-2 cursor-pointer text-xl hover:text-[#ff683f]">
          <BiSolidPhoneCall />
        </CustomIcon>
        <CustomIcon className="mx-2 cursor-pointer text-xl hover:text-[#ff683f]">
          <AiTwotoneMail />
        </CustomIcon>
        <CustomIcon className="mx-2 cursor-pointer text-xl hover:text-[#ff683f]">
          <BsWhatsapp />
        </CustomIcon>
        <CustomIcon className="mx-2 cursor-pointer text-xl hover:text-[#ff683f]">
          <AiFillFacebook />
        </CustomIcon>
      </div>
    </div>
  );
};

export default CeoProfile;
