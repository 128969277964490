import React from "react";
import PageLayout from "../../components/Common/PageLayout/PageLayout";
import linksList from "../../utils/linksList";
import StudyInNewZealandAccrodian from "../../components/Services/StudyInNewZealandAccrodian/StudyInNewZealandAccrodian";
import { Helmet } from "react-helmet";

const StudyInNewZealand = () => {
  return (
    <div>
      <Helmet>
        <title>Russell Global Consultancy | Study In New Zealand</title>
        <meta
          name="description"
          content="Russell Global Consultancy Study In New Zealand Page"
        />
        <link
          rel="canonical"
          href="https://russellglobalconsultancy.com/services/study-newzealand"
        />
      </Helmet>
      <PageLayout
        header={"STUDY IN NEW ZEALAND"}
        subHeader={"Thinking about studying in NEW ZEALAND from Bangladesh?"}
        title={"STUDY IN NEW ZEALAND"}
        subTitle={
          "Thinking about studying in NEW ZEALAND from Bangladesh and you need help in selecting a program, the right university? Russell Global Consultancy can help you! Process your application through the British Council Trained Agent Consultants and British Graduate."
        }
        links={linksList}
      >
        <StudyInNewZealandAccrodian />
      </PageLayout>
    </div>
  );
};

export default StudyInNewZealand;
