import React, { useState } from "react";
import ProfessionAccrodianBody from "./ProfessionAccrodianBody";
import "./ProfessionAccrodian.css";

const ProfessionAccrodian = () => {
  const [collapse1, setCollapse1] = useState(true);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [collapse4, setCollapse4] = useState(false);
  const [collapse5, setCollapse5] = useState(false);
  const [collapse6, setCollapse6] = useState(false);
  const [collapse7, setCollapse7] = useState(false);
  const [collapse8, setCollapse8] = useState(false);
  const [collapse9, setCollapse9] = useState(false);
  const [collapse10, setCollapse10] = useState(false);
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      {/* 1 */}
      <h2
        id="accordion-collapse-heading-1"
        onClick={() => {
          const t = collapse1;
          setCollapse1(!collapse1);
          setCollapse2(false);
          setCollapse3(false);
          setCollapse4(false);
          setCollapse5(false);
          setCollapse6(false);
          setCollapse7(false);
          setCollapse8(false);
          setCollapse9(false);
          setCollapse10(false);
          if (t) {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              el.style.height = "0px";
              el.style.transition = "height 0.5s ease-in-out";
            }
          } else {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              el.style.height = "240px";
              el.style.transition = "height 0.5s ease-in-out";
            }
            // 2
            const el2 = document?.getElementById("accordion-collapse-body-2");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
            // 3
            const el3 = document?.getElementById("accordion-collapse-body-3");
            if (el3) {
              el3.style.height = "0px";
              el3.style.transition = "height 0.5s ease-in-out";
            }
            // 4
            const el4 = document?.getElementById("accordion-collapse-body-4");
            if (el4) {
              el4.style.height = "0px";
              el4.style.transition = "height 0.5s ease-in-out";
            }
            // 5
            const el5 = document?.getElementById("accordion-collapse-body-5");
            if (el5) {
              el5.style.height = "0px";
              el5.style.transition = "height 0.5s ease-in-out";
            }
            // 6
            const el6 = document?.getElementById("accordion-collapse-body-6");
            if (el6) {
              el6.style.height = "0px";
              el6.style.transition = "height 0.5s ease-in-out";
            }
            // 7
            const el7 = document?.getElementById("accordion-collapse-body-7");
            if (el7) {
              el7.style.height = "0px";
              el7.style.transition = "height 0.5s ease-in-out";
            }
            // 8
            const el8 = document?.getElementById("accordion-collapse-body-8");
            if (el8) {
              el8.style.height = "0px";
              el8.style.transition = "height 0.5s ease-in-out";
            }
            // 9
            const el9 = document?.getElementById("accordion-collapse-body-9");
            if (el9) {
              el9.style.height = "0px";
              el9.style.transition = "height 0.5s ease-in-out";
            }
            // 10
            const el10 = document?.getElementById("accordion-collapse-body-10");
            if (el10) {
              el10.style.height = "0px";
              el10.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded="true"
          aria-controls="accordion-collapse-body-1"
        >
          <span
            className={`accrodian-body-title relative text-md md:text-xl font-semibold ${
              collapse1 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse1 ? "circle" : "circle-inactive"}`}
            ></span>
            Administration and Sales Staff
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-1"
        className={`${collapse1 ? "" : ""} h-[240px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-1"
      >
        <ProfessionAccrodianBody>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">Accountants</li>
            <li className="relative left-[18px] my-2">Computer Operators</li>
            <li className="relative left-[18px] my-2">Executive</li>
            <li className="relative left-[18px] my-2">Secretaries</li>
            <li className="relative left-[18px] my-2">Salesmen</li>
            <li className="relative left-[18px] my-2">General Helpers</li>
          </ul>
        </ProfessionAccrodianBody>
      </div>
      {/* 2 */}
      <h2
        id="accordion-collapse-heading-2"
        onClick={() => {
          const t = collapse2;
          setCollapse2(!collapse2);
          setCollapse1(false);
          setCollapse3(false);
          setCollapse4(false);
          setCollapse5(false);
          setCollapse6(false);
          setCollapse7(false);
          setCollapse8(false);
          setCollapse9(false);
          setCollapse10(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-2");
            if (el) {
              el.style.height = "200px";
              el.style.transition = "height 0.5s ease-in-out";

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-3");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-4");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
              // 5
              const el5 = document?.getElementById("accordion-collapse-body-5");
              if (el5) {
                el5.style.height = "0px";
                el5.style.transition = "height 0.5s ease-in-out";
              }
              // 6
              const el6 = document?.getElementById("accordion-collapse-body-6");
              if (el6) {
                el6.style.height = "0px";
                el6.style.transition = "height 0.5s ease-in-out";
              }
              // 7
              const el7 = document?.getElementById("accordion-collapse-body-7");
              if (el7) {
                el7.style.height = "0px";
                el7.style.transition = "height 0.5s ease-in-out";
              }
              // 8
              const el8 = document?.getElementById("accordion-collapse-body-8");
              if (el8) {
                el8.style.height = "0px";
                el8.style.transition = "height 0.5s ease-in-out";
              }

              // 9
              const el9 = document?.getElementById("accordion-collapse-body-9");
              if (el9) {
                el9.style.height = "0px";
                el9.style.transition = "height 0.5s ease-in-out";
              }
              // 10
              const el10 = document?.getElementById(
                "accordion-collapse-body-10"
              );
              if (el10) {
                el10.style.height = "0px";
                el10.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-2");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-2"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-2"
        >
          <span
            className={`accrodian-body-title relative text-md md:text-xl font-semibold ${
              collapse2 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse2 ? "circle" : "circle-inactive"}`}
            ></span>
            Air-conditioning Workers
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-2"
        className={`${collapse2 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-2"
      >
        <ProfessionAccrodianBody>
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
            <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
              <li className="relative left-[18px] my-2">General Helpers</li>
              <li className="relative left-[18px] my-2">Central Plants</li>
              <li className="relative left-[18px] my-2">Ducting Works</li>
              <li className="relative left-[18px] my-2">Insulation Work</li>
            </ul>
          </div>
        </ProfessionAccrodianBody>
      </div>
      {/* 3 */}
      <h2
        id="accordion-collapse-heading-3"
        onClick={() => {
          const t = collapse3;
          setCollapse3(!collapse3);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse4(false);
          setCollapse5(false);
          setCollapse6(false);
          setCollapse7(false);
          setCollapse8(false);
          setCollapse9(false);
          setCollapse10(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-3");
            if (el) {
              el.style.height = "150px";
              el.style.transition = "height 0.5s ease-in-out";

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-4");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
              // 5
              const el5 = document?.getElementById("accordion-collapse-body-5");
              if (el5) {
                el5.style.height = "0px";
                el5.style.transition = "height 0.5s ease-in-out";
              }
              // 6
              const el6 = document?.getElementById("accordion-collapse-body-6");
              if (el6) {
                el6.style.height = "0px";
                el6.style.transition = "height 0.5s ease-in-out";
              }
              // 7
              const el7 = document?.getElementById("accordion-collapse-body-7");
              if (el7) {
                el7.style.height = "0px";
                el7.style.transition = "height 0.5s ease-in-out";
              }
              // 8
              const el8 = document?.getElementById("accordion-collapse-body-8");
              if (el8) {
                el8.style.height = "0px";
                el8.style.transition = "height 0.5s ease-in-out";
              }
              // 9
              const el9 = document?.getElementById("accordion-collapse-body-9");
              if (el9) {
                el9.style.height = "0px";
                el9.style.transition = "height 0.5s ease-in-out";
              }
              // 10
              const el10 = document?.getElementById(
                "accordion-collapse-body-10"
              );
              if (el10) {
                el10.style.height = "0px";
                el10.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-3");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-3"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-3"
        >
          <span
            className={`accrodian-body-title relative text-md md:text-xl font-semibold ${
              collapse3 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse3 ? "circle" : "circle-inactive"}`}
            ></span>
            Marine Engineering Workers
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-3"
        className={`${collapse3 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-3"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">Fitters</li>
            <li className="relative left-[18px] my-2">Marines Mechanics</li>
            <li className="relative left-[18px] my-2">Welder</li>
          </ul>
        </div>
      </div>
      {/* 4 */}
      <h2
        id="accordion-collapse-heading-4"
        onClick={() => {
          const t = collapse4;
          setCollapse4(!collapse4);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse3(false);
          setCollapse5(false);
          setCollapse6(false);
          setCollapse7(false);
          setCollapse8(false);
          setCollapse9(false);
          setCollapse10(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-4");
            if (el) {
              el.style.height = "300px";
              el.style.transition = "height 0.5s ease-in-out";

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-3");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
              // 5
              const el5 = document?.getElementById("accordion-collapse-body-5");
              if (el5) {
                el5.style.height = "0px";
                el5.style.transition = "height 0.5s ease-in-out";
              }
              // 6
              const el6 = document?.getElementById("accordion-collapse-body-6");
              if (el6) {
                el6.style.height = "0px";
                el6.style.transition = "height 0.5s ease-in-out";
              }
              // 7
              const el7 = document?.getElementById("accordion-collapse-body-7");
              if (el7) {
                el7.style.height = "0px";
                el7.style.transition = "height 0.5s ease-in-out";
              }
              // 8
              const el8 = document?.getElementById("accordion-collapse-body-8");
              if (el8) {
                el8.style.height = "0px";
                el8.style.transition = "height 0.5s ease-in-out";
              }
              // 9
              const el9 = document?.getElementById("accordion-collapse-body-9");
              if (el9) {
                el9.style.height = "0px";
                el9.style.transition = "height 0.5s ease-in-out";
              }
              // 10
              const el10 = document?.getElementById(
                "accordion-collapse-body-10"
              );
              if (el10) {
                el10.style.height = "0px";
                el10.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-4");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-4"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-4"
        >
          <span
            className={`accrodian-body-title relative text-md md:text-xl font-semibold ${
              collapse4 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse4 ? "circle" : "circle-inactive"}`}
            ></span>
            Hotel Personnel
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-4"
        className={`${collapse4 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-4"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">Cashiers</li>
            <li className="relative left-[18px] my-2">Chefs / Cooks</li>
            <li className="relative left-[18px] my-2">Receptionist</li>
            <li className="relative left-[18px] my-2">Restaurant Managers</li>
            <li className="relative left-[18px] my-2">Security Guards</li>
            <li className="relative left-[18px] my-2">Storekeepers</li>
            <li className="relative left-[18px] my-2">CUtility workers</li>
            <li className="relative left-[18px] my-2">Waiters / Waitresses</li>
          </ul>
        </div>
      </div>

      {/* 5 */}
      <h2
        id="accordion-collapse-heading-5"
        onClick={() => {
          const t = collapse5;
          setCollapse5(!collapse5);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse3(false);
          setCollapse4(false);
          setCollapse6(false);
          setCollapse7(false);
          setCollapse8(false);
          setCollapse9(false);
          setCollapse10(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-5");
            if (el) {
              el.style.height = "100px";
              el.style.transition = "height 0.5s ease-in-out";

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-3");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
              // 5
              const el5 = document?.getElementById("accordion-collapse-body-4");
              if (el5) {
                el5.style.height = "0px";
                el5.style.transition = "height 0.5s ease-in-out";
              }
              //6
              const el6 = document?.getElementById("accordion-collapse-body-6");
              if (el6) {
                el6.style.height = "0px";
                el6.style.transition = "height 0.5s ease-in-out";
              }
              //7
              const el7 = document?.getElementById("accordion-collapse-body-7");
              if (el7) {
                el7.style.height = "0px";
                el7.style.transition = "height 0.5s ease-in-out";
              }
              //8
              const el8 = document?.getElementById("accordion-collapse-body-8");
              if (el8) {
                el8.style.height = "0px";
                el8.style.transition = "height 0.5s ease-in-out";
              }
              // 9
              const el9 = document?.getElementById("accordion-collapse-body-9");
              if (el9) {
                el9.style.height = "0px";
                el9.style.transition = "height 0.5s ease-in-out";
              }
              // 10
              const el10 = document?.getElementById(
                "accordion-collapse-body-10"
              );
              if (el10) {
                el10.style.height = "0px";
                el10.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-5");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-5"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-5"
        >
          <span
            className={`accrodian-body-title relative text-md md:text-xl font-semibold ${
              collapse5 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse5 ? "circle" : "circle-inactive"}`}
            ></span>
            Furniture and Interior Decoration Workers
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-5"
        className={`${collapse5 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-5"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">Furniture Carpenters</li>
            <li className="relative left-[18px] my-2">Furniture Painters</li>
          </ul>
        </div>
      </div>

      {/* 6 */}
      <h2
        id="accordion-collapse-heading-6"
        onClick={() => {
          const t = collapse6;
          setCollapse6(!collapse6);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse3(false);
          setCollapse4(false);
          setCollapse5(false);
          setCollapse7(false);
          setCollapse8(false);
          setCollapse9(false);
          setCollapse10(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-6");
            if (el) {
              el.style.height = "100px";
              el.style.transition = "height 0.5s ease-in-out";

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-3");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
              // 5
              const el5 = document?.getElementById("accordion-collapse-body-4");
              if (el5) {
                el5.style.height = "0px";
                el5.style.transition = "height 0.5s ease-in-out";
              }
              // 6
              const el6 = document?.getElementById("accordion-collapse-body-5");
              if (el6) {
                el6.style.height = "0px";
                el6.style.transition = "height 0.5s ease-in-out";
              }
              // 6
              const el7 = document?.getElementById("accordion-collapse-body-7");
              if (el7) {
                el7.style.height = "0px";
                el7.style.transition = "height 0.5s ease-in-out";
              }
              // 6
              const el8 = document?.getElementById("accordion-collapse-body-8");
              if (el8) {
                el8.style.height = "0px";
                el8.style.transition = "height 0.5s ease-in-out";
              }
              // 9
              const el9 = document?.getElementById("accordion-collapse-body-9");
              if (el9) {
                el9.style.height = "0px";
                el9.style.transition = "height 0.5s ease-in-out";
              }
              // 10
              const el10 = document?.getElementById(
                "accordion-collapse-body-10"
              );
              if (el10) {
                el10.style.height = "0px";
                el10.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-6");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-5"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-6"
        >
          <span
            className={`accrodian-body-title relative text-md md:text-xl font-semibold ${
              collapse6 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse6 ? "circle" : "circle-inactive"}`}
            ></span>
            Cleaning and Maintenance Workers
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-6"
        className={`${collapse6 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-6"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">Supervisors</li>
            <li className="relative left-[18px] my-2">Cleaners</li>
          </ul>
        </div>
      </div>

      {/* 7 */}
      <h2
        id="accordion-collapse-heading-7"
        onClick={() => {
          const t = collapse7;
          setCollapse7(!collapse7);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse3(false);
          setCollapse4(false);
          setCollapse5(false);
          setCollapse6(false);
          setCollapse8(false);
          setCollapse9(false);
          setCollapse10(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-7");
            if (el) {
              el.style.height = "200px";
              el.style.transition = "height 0.5s ease-in-out";

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-3");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
              // 5
              const el5 = document?.getElementById("accordion-collapse-body-4");
              if (el5) {
                el5.style.height = "0px";
                el5.style.transition = "height 0.5s ease-in-out";
              }
              // 6
              const el6 = document?.getElementById("accordion-collapse-body-5");
              if (el6) {
                el6.style.height = "0px";
                el6.style.transition = "height 0.5s ease-in-out";
              }
              // 7
              const el7 = document?.getElementById("accordion-collapse-body-6");
              if (el7) {
                el7.style.height = "0px";
                el7.style.transition = "height 0.5s ease-in-out";
              }
              // 8
              const el8 = document?.getElementById("accordion-collapse-body-8");
              if (el8) {
                el8.style.height = "0px";
                el8.style.transition = "height 0.5s ease-in-out";
              }
              // 9
              const el9 = document?.getElementById("accordion-collapse-body-9");
              if (el9) {
                el9.style.height = "0px";
                el9.style.transition = "height 0.5s ease-in-out";
              }
              // 10
              const el10 = document?.getElementById(
                "accordion-collapse-body-10"
              );
              if (el10) {
                el10.style.height = "0px";
                el10.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-7");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-7"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-7"
        >
          <span
            className={`accrodian-body-title relative text-md md:text-xl font-semibold ${
              collapse7 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse7 ? "circle" : "circle-inactive"}`}
            ></span>
            Factory Workers
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-7"
        className={`${collapse7 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-7"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">Sales</li>
            <li className="relative left-[18px] my-2">Packing Helper</li>
            <li className="relative left-[18px] my-2">Delivery Driver</li>
            <li className="relative left-[18px] my-2">Machine Operators</li>
            <li className="relative left-[18px] my-2">Product assemblers</li>
          </ul>
        </div>
      </div>

      {/* 8 */}
      <h2
        id="accordion-collapse-heading-8"
        onClick={() => {
          const t = collapse8;
          setCollapse8(!collapse8);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse3(false);
          setCollapse4(false);
          setCollapse5(false);
          setCollapse6(false);
          setCollapse7(false);
          setCollapse9(false);
          setCollapse10(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-8");
            if (el) {
              el.style.height = "180px";
              el.style.transition = "height 0.5s ease-in-out";

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-3");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
              // 5
              const el5 = document?.getElementById("accordion-collapse-body-4");
              if (el5) {
                el5.style.height = "0px";
                el5.style.transition = "height 0.5s ease-in-out";
              }
              // 6
              const el6 = document?.getElementById("accordion-collapse-body-5");
              if (el6) {
                el6.style.height = "0px";
                el6.style.transition = "height 0.5s ease-in-out";
              }
              // 7
              const el7 = document?.getElementById("accordion-collapse-body-6");
              if (el7) {
                el7.style.height = "0px";
                el7.style.transition = "height 0.5s ease-in-out";
              }
              // 8
              const el8 = document?.getElementById("accordion-collapse-body-7");
              if (el8) {
                el8.style.height = "0px";
                el8.style.transition = "height 0.5s ease-in-out";
              }
              // 9
              const el9 = document?.getElementById("accordion-collapse-body-9");
              if (el9) {
                el9.style.height = "0px";
                el9.style.transition = "height 0.5s ease-in-out";
              }
              // 10
              const el10 = document?.getElementById(
                "accordion-collapse-body-10"
              );
              if (el10) {
                el10.style.height = "0px";
                el10.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-8");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-8"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-5"
        >
          <span
            className={`accrodian-body-title relative text-md md:text-xl font-semibold ${
              collapse8 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse8 ? "circle" : "circle-inactive"}`}
            ></span>
            Health and Safety
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-8"
        className={`${collapse8 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-8"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">Nurse</li>
            <li className="relative left-[18px] my-2">Care Giver</li>
            <li className="relative left-[18px] my-2">Forklift operator</li>
            <li className="relative left-[18px] my-2">Firefighter</li>
          </ul>
        </div>
      </div>

      {/* 9 */}
      <h2
        id="accordion-collapse-heading-9"
        onClick={() => {
          const t = collapse9;
          setCollapse9(!collapse9);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse3(false);
          setCollapse4(false);
          setCollapse5(false);
          setCollapse6(false);
          setCollapse7(false);
          setCollapse8(false);
          setCollapse10(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-9");
            if (el) {
              el.style.height = "290px";
              el.style.transition = "height 0.5s ease-in-out";

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-3");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
              // 5
              const el5 = document?.getElementById("accordion-collapse-body-4");
              if (el5) {
                el5.style.height = "0px";
                el5.style.transition = "height 0.5s ease-in-out";
              }
              // 6
              const el6 = document?.getElementById("accordion-collapse-body-5");
              if (el6) {
                el6.style.height = "0px";
                el6.style.transition = "height 0.5s ease-in-out";
              }
              // 7
              const el7 = document?.getElementById("accordion-collapse-body-6");
              if (el7) {
                el7.style.height = "0px";
                el7.style.transition = "height 0.5s ease-in-out";
              }
              // 8
              const el8 = document?.getElementById("accordion-collapse-body-7");
              if (el8) {
                el8.style.height = "0px";
                el8.style.transition = "height 0.5s ease-in-out";
              }
              // 9
              const el9 = document?.getElementById("accordion-collapse-body-8");
              if (el9) {
                el9.style.height = "0px";
                el9.style.transition = "height 0.5s ease-in-out";
              }
              // 10
              const el10 = document?.getElementById(
                "accordion-collapse-body-10"
              );
              if (el10) {
                el10.style.height = "0px";
                el10.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-9");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-9"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-9"
        >
          <span
            className={`accrodian-body-title relative text-md md:text-xl font-semibold ${
              collapse9 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse9 ? "circle" : "circle-inactive"}`}
            ></span>
            Construction Workers
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-9"
        className={`${collapse9 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-9"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">General Labor</li>
            <li className="relative left-[18px] my-2">Construction Labor </li>
            <li className="relative left-[18px] my-2">Carpenters</li>
            <li className="relative left-[18px] my-2">Electricians</li>
            <li className="relative left-[18px] my-2">Plumbers</li>
            <li className="relative left-[18px] my-2">Painters</li>
            <li className="relative left-[18px] my-2">Drivers</li>
            <li className="relative left-[18px] my-2">
              Heavy Equipment Operators
            </li>
          </ul>
        </div>
      </div>

      {/* 10 */}
      <h2
        id="accordion-collapse-heading-10"
        onClick={() => {
          const t = collapse10;
          setCollapse10(!collapse10);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse3(false);
          setCollapse4(false);
          setCollapse5(false);
          setCollapse6(false);
          setCollapse7(false);
          setCollapse8(false);
          setCollapse9(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-10");
            if (el) {
              el.style.height = "240px";
              el.style.transition = "height 0.5s ease-in-out";

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-3");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
              // 5
              const el5 = document?.getElementById("accordion-collapse-body-4");
              if (el5) {
                el5.style.height = "0px";
                el5.style.transition = "height 0.5s ease-in-out";
              }
              // 6
              const el6 = document?.getElementById("accordion-collapse-body-5");
              if (el6) {
                el6.style.height = "0px";
                el6.style.transition = "height 0.5s ease-in-out";
              }
              // 7
              const el7 = document?.getElementById("accordion-collapse-body-6");
              if (el7) {
                el7.style.height = "0px";
                el7.style.transition = "height 0.5s ease-in-out";
              }
              // 8
              const el8 = document?.getElementById("accordion-collapse-body-7");
              if (el8) {
                el8.style.height = "0px";
                el8.style.transition = "height 0.5s ease-in-out";
              }
              // 9
              const el9 = document?.getElementById("accordion-collapse-body-8");
              if (el9) {
                el9.style.height = "0px";
                el9.style.transition = "height 0.5s ease-in-out";
              }
              // 10
              const el10 = document?.getElementById(
                "accordion-collapse-body-9"
              );
              if (el10) {
                el10.style.height = "0px";
                el10.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-10");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-10"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-10"
        >
          <span
            className={`accrodian-body-title relative text-md md:text-xl font-semibold ${
              collapse10 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse10 ? "circle" : "circle-inactive"}`}
            ></span>
            Supermarket staff
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-10"
        className={`${collapse10 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-10"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">Manager</li>
            <li className="relative left-[18px] my-2">Cashier</li>
            <li className="relative left-[18px] my-2">Shopkeeper</li>
            <li className="relative left-[18px] my-2">Supermarket Cleaner</li>
            <li className="relative left-[18px] my-2">
              Heavy Equipment Operators
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProfessionAccrodian;
