import React from "react";
import PageLayout from "../../components/Common/PageLayout/PageLayout";
import linksList from "../../utils/linksList";
import StudyInAustraliaAccrodian from "../../components/Services/StudyInAustraliaAccrodian/StudyInAustraliaAccrodian";
import { Helmet } from "react-helmet";

const StudyInAustralia = () => {
  return (
    <div>
      <Helmet>
        <title>Russell Global Consultancy | Study In Australia</title>
        <meta
          name="description"
          content="Russell Global Consultancy Study In Australia Page"
        />
        <link
          rel="canonical"
          href="https://russellglobalconsultancy.com/services/study-australia"
        />
      </Helmet>
      <PageLayout
        header={"STUDY IN AUSTRALIA"}
        subHeader={"Thinking about studying in AUSTRALIA from Bangladesh?"}
        title={"STUDY IN AUSTRALIA"}
        subTitle={
          "Thinking about studying in AUSTRALIA from Bangladesh and you need help in selecting a program, the right university? Russell Global Consultancy can help you! Process your application through the British Council Trained Agent Consultants and British Graduate."
        }
        links={linksList}
      >
        <StudyInAustraliaAccrodian />
      </PageLayout>
    </div>
  );
};

export default StudyInAustralia;
