import React from "react";
import CustomIcon from "../CustomIcon/CustomIcon";
import { SlClose } from "react-icons/sl";
import "react-datepicker/dist/react-datepicker.css";
import { date, hour, min, month, shift } from "../../../utils/date";
import "./ContactModal.css";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const ContactModal = ({ showModal, setShowModal }) => {
  const sendMail = async (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_87fjkbj",
        "template_gpm88q6",
        e.target,
        "KPI9eXPHujv4z_1zb"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          setShowModal(!showModal);
          toast.success("Sent successfully");
        },
        (error) => {
          console.log(error.text);
          toast.error("Faild to sent!");
        }
      );
  };
  return (
    <>
      <div
        id="authentication-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={`fixed bg-[#0000007d] z-50 w-full top-0 p-4 pb-32 overflow-x-hidden overflow-y-hidden md:inset-0 h-full flex justify-center items-center ${
          showModal ? "" : "hidden"
        }`}
      >
        <div className="relative w-[95%] lg:w-[45%] top-[75px] h-full overflow-y-hidden">
          <button
            onClick={() => setShowModal(!showModal)}
            type="button"
            className="modal-close absolute top-0 right-0 z-10 text-gray-400 bg-[#002147] p-1 w-full text-lg ml-auto shadow-md shadow-[#002147] inline-flex justify-end items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="authentication-modal"
          >
            <CustomIcon className="text-red-600 hover:text-gray-200 w-8 md:w-[3rem] lg:w-[2.5rem] h-8 md:h-[4rem] text-lg">
              <SlClose />
            </CustomIcon>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="relative bg-[#002147] rounded-lg h-full shadow dark:bg-gray-700 overflow-y-auto">
            <div className=" flex justify-center items-center flex-col px-8 md:px-16 lg:mt-[1.8rem] mt-[2.5rem] mb-12 p-10">
              <h2 className="text-xl font-extrabold text-white text-center mb-6 lg:mb-0">
                Book an Appointment
              </h2>
              <div className="flex w-full">
                <form
                  onSubmit={(e) => sendMail(e)}
                  className="w-full z-[2] p-0 md:p-8 rounded-md flex flex-col justify-center"
                >
                  <div className="mb-2">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-start text-xs font-medium text-white dark:text-white"
                    >
                      Your email
                    </label>
                    <input
                      name="email"
                      type="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="name@something.com"
                      required
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="text"
                      className="block mb-2 text-start text-xs font-medium text-white dark:text-white"
                    >
                      Subject
                    </label>
                    <input
                      name="subject"
                      type="text"
                      id="text"
                      placeholder="I want to descuss about your Study/Recruitment service."
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                  <div className="mb-2 w-full grid grid-cols-2 lg:grid-cols-3">
                    <div className="flex flex-col justify-center items-start">
                      <label
                        htmlFor="date"
                        className="block mb-2 text-start text-xs font-medium text-white dark:text-white"
                      >
                        Date
                      </label>
                      <select
                        name="date"
                        id="date"
                        className="text-md p-1 rounded-md"
                      >
                        <option>--Select--</option>
                        {date?.map((d, i) => (
                          <option key={i} value={d}>
                            {d}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col justify-center items-start">
                      <label
                        htmlFor="month"
                        className="block mb-2 text-start text-xs font-medium text-white dark:text-white"
                      >
                        Month
                      </label>
                      <select
                        name="month"
                        id="month"
                        className="text-md p-1 rounded-md"
                      >
                        <option>--Select--</option>
                        {month?.map((m, i) => (
                          <option key={i} value={m}>
                            {m}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col justify-center items-start">
                      <label
                        htmlFor="year"
                        className="block mb-2 text-start text-xs font-medium text-white dark:text-white"
                      >
                        Year
                      </label>
                      <select
                        name="year"
                        id="year"
                        defaultValue={new Date().getFullYear()}
                        className="text-md p-1 rounded-md"
                      >
                        <option defaultValue={new Date().getFullYear()}>
                          {new Date().getFullYear()}
                        </option>
                      </select>
                    </div>
                    <div className="flex flex-col justify-center items-start">
                      <label
                        htmlFor="hour"
                        className="block mb-2 text-start text-xs font-medium text-white dark:text-white"
                      >
                        Hour
                      </label>
                      <select
                        name="hour"
                        id="hour"
                        className="text-md p-1 rounded-md"
                      >
                        <option>--Select--</option>
                        {hour.map((h, i) => (
                          <option key={i} value={h}>
                            {h}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col justify-center items-start">
                      <label
                        htmlFor="min"
                        className="block mb-2 text-start text-xs font-medium text-white dark:text-white"
                      >
                        Minute
                      </label>
                      <select
                        name="min"
                        id="min"
                        className="text-md p-1 rounded-md"
                      >
                        <option>--Select--</option>
                        {min.map((m, i) => (
                          <option key={i} value={m}>
                            {m}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col justify-center items-start">
                      <label
                        htmlFor="shift"
                        className="block mb-2 text-start text-xs font-medium text-white dark:text-white"
                      >
                        Format
                      </label>
                      <select
                        name="format"
                        id="shift"
                        defaultValue={"AM"}
                        className="text-md p-1 rounded-md"
                      >
                        <option>--Select--</option>
                        {shift.map((s, i) => (
                          <option key={i} value={s}>
                            {s}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="message"
                      className="block mb-2 text-start text-xs font-medium text-white dark:text-white"
                    >
                      Your message
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      rows="4"
                      className="block p-2.5 w-full text-xs text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Leave a comment..."
                    ></textarea>
                  </div>
                  <input
                    type="submit"
                    className="text-white mt-2 cursor-pointer bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    value="Submit"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactModal;
