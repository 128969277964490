import React from "react";
import PageLayout from "../../components/Common/PageLayout/PageLayout";
import linksList from "../../utils/linksList";
import ProfessionAccrodian from "../../components/Services/ProfessionAccrodian/ProfessionAccrodian";
import { Helmet } from "react-helmet";

const RecruitmentProfessions = () => {
  return (
    <div>
      <Helmet>
        <title>Russell Global Consultancy | Recruitment Professions</title>
        <meta
          name="description"
          content="Russell Global Consultancy Recruitment Professions Page"
        />
        <link
          rel="canonical"
          href="https://russellglobalconsultancy.com/services/recruitment-professions"
        />
      </Helmet>
      <PageLayout
        header={"PROFESSIONS"}
        subHeader={"Hire Globally"}
        title={"Professions"}
        subTitle={
          "We have a wide client base that includes companies in the hotel and business industries, hospitality, oil and refinery, petrochemicals, engineering, cruise liners, automotive, and other industries."
        }
        links={linksList}
      >
        <ProfessionAccrodian />
      </PageLayout>
    </div>
  );
};

export default RecruitmentProfessions;
