import React from "react";
import SectionHeader from "../SectionHeader/SectionHeader";
import ChooseCountryCard from "../ChooseCountryCard/ChooseCountryCard";
import australia_flag from "../../../assets/images/australia-flag.png";
import newziland_flag from "../../../assets/images/newziland-flag.jpg";
import europe_flag from "../../../assets/images/Europe-flag.jpg";
import uk_flag from "../../../assets/images/uk-flag.png";
import china_flag from "../../../assets/images/china-flag.jpg";
import world_map from "../../../assets/images/world-map.png";
import { Link } from "react-router-dom";

const ChooseYourCountry = () => {
  return (
    <div className="px-6 md:px-12 lg:px-32 py-6">
      <SectionHeader
        title={"Choose Your Country"}
        description={
          "Russell Global Consultancy processes Student Visa, Student Spouse Visa and Parents Visitors Visa for Australia, New Zealand, UK, Europe and China."
        }
      />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-12">
        <Link to={"/services/study-australia"}>
          <ChooseCountryCard
            image={australia_flag}
            title={"Study in Australia"}
            details={
              "Australia has long been the preferred overseas study destinations. Multicultural Australia is a safe, friendly, sophisticated ..."
            }
          />
        </Link>
        <Link to={"/services/study-newzealand"}>
          <ChooseCountryCard
            image={newziland_flag}
            title={"Study in New Zealand"}
            details={
              "Most students prefer to study in New Zealand because it is the most popular and cost effective study destination in the world ..."
            }
          />
        </Link>
        <Link to={"/services/study-uk"}>
          <ChooseCountryCard
            image={uk_flag}
            title={"Study in UK"}
            details={
              "One of the main advantages of studying in the UK is that you will be exposed to different cultures and experiences ..."
            }
          />
        </Link>
        <Link to={"/services/study-europe"}>
          <ChooseCountryCard
            image={europe_flag}
            title={"Study in Europe"}
            details={
              "Europe is an increasingly popular place to get tertiary education with more than 40,000 international students from over ..."
            }
          />
        </Link>
        <Link to={"/services/study-china"}>
          <ChooseCountryCard
            image={china_flag}
            title={"Study in China"}
            details={
              "Studying in the China doesn’t just give you tangible degrees and certificates. For any international student, the China has a lot ..."
            }
          />
        </Link>
        <Link to={"/services/recruitment-services"}>
          <ChooseCountryCard
            image={world_map}
            title={"Other Visa Services"}
            details={
              "Russell Global Consultancy also processes student dependent/spouse visa, Job visa, family visit visa ..."
            }
          />
        </Link>
      </div>
    </div>
  );
};

export default ChooseYourCountry;
