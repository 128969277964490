import React from "react";
import HeroBanner from "../../components/HomePageComponents/HeroBanner/HeroBanner";
import ChooseYourCountry from "../../components/HomePageComponents/ChooseYourCountry/ChooseYourCountry";
import Count from "../../components/HomePageComponents/Count/Count";
import HowWeWork from "../../components/HomePageComponents/HowWeWork/HowWeWork";
import Testimonial from "../../components/HomePageComponents/Testimonial/Testimonial";
import ContactUs from "../../components/HomePageComponents/ContactUs/ContactUs";
import UniSlider from "../../components/HomePageComponents/UniSlider/UniSlider";
import ChooseUs from "../../components/HomePageComponents/ChooseUs/ChooseUs";
import AboutUs from "../../components/HomePageComponents/AboutUs/AboutUs";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div style={{ maxWidth: "100%", overflow: "hidden" }}>
      <Helmet>
        <title>Russell Global Consultancy</title>
        <meta name="description" content="Russell Global Consultancy" />
        <link rel="canonical" href="https://russellglobalconsultancy.com/" />
      </Helmet>
      <HeroBanner />
      <ChooseYourCountry />
      <ChooseUs />
      <div className="bg-white">
        <Count />
        <AboutUs />
      </div>
      <HowWeWork />
      <UniSlider />
      <Testimonial />
      <ContactUs />
    </div>
  );
};

export default Home;
