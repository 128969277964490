import React from "react";
import PageLayout from "../../components/Common/PageLayout/PageLayout";
import linksList from "../../utils/linksList";
import AboutUsAccrodian from "../../components/Overviews/AboutUs/AboutUsAccrodian";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <title>Russell Global Consultancy | About Us</title>
        <meta
          name="description"
          content="Russell Global Consultancy About Us Page"
        />
        <link
          rel="canonical"
          href="https://russellglobalconsultancy.com/overview/about-us"
        />
      </Helmet>
      <PageLayout
        header={"ABOUT US"}
        subHeader={"Best Study Abroad & Recruitment Agency in Bangladesh"}
        title={"About Us"}
        subTitle={
          "Russell Global Consultancy Education & Recruitment Consultants is one of the leading and best study abroad agency in Dhaka, Bangladesh."
        }
        links={linksList}
      >
        <AboutUsAccrodian />
      </PageLayout>
    </div>
  );
};

export default AboutUs;
