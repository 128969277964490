import React from "react";
import emailjs from "@emailjs/browser";
import "./ContactUs.css";
import { toast } from "react-toastify";

const ContactUs = () => {
  const sendMail = async (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_87fjkbj",
        "template_jobwbhh",
        e.target,
        "KPI9eXPHujv4z_1zb"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          toast.success("Sent successfully");
        },
        (error) => {
          console.log(error.text);
          toast.error("Faild to sent!");
        }
      );
  };

  return (
    <div className="flex justify-center items-center flex-col px-4 md:px-16 mb-12">
      <h2 className="text-2xl font-bold text-[#002147] text-center lg:text-5xl mb-14">
        Contact Us
      </h2>
      <div className="flex w-full contact-form-container">
        <form
          onSubmit={(e) => sendMail(e)}
          className="w-full md:w-1/2 bg-gray-100 p-8 rounded-md"
        >
          <div className="mb-6">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@something.com"
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="text"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Subject
            </label>
            <input
              type="text"
              id="text"
              name="subject"
              placeholder="Write your subject..."
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="text"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Topic
            </label>
            <select
              name="topic"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option defaultValue={"Education services"}>
                Education services
              </option>
              <option value={"Recruitment services"}>
                Recruitment services
              </option>
            </select>
          </div>
          <div className="mb-6">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your message
            </label>
            <textarea
              id="message"
              rows="4"
              name="message"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Leave a comment..."
            ></textarea>
          </div>
          <input
            type="submit"
            value="Submit"
            className="text-white cursor-pointer bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          />
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
