import React, { useState } from "react";
import StudyInEuropeAccrodianBody from "./StudyInEuropeAccrodianBody";
import "./StudyEuropeAccrodian.css";

const StudyInEuropeAccrodian = () => {
  const [collapse1, setCollapse1] = useState(true);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [collapse4, setCollapse4] = useState(false);
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <h2
        id="accordion-collapse-heading-1"
        onClick={() => {
          const t = collapse1;
          setCollapse1(!collapse1);
          setCollapse2(false);
          setCollapse3(false);
          setCollapse4(false);
          if (t) {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              el.style.height = "0px";
              el.style.transition = "height 0.5s ease-in-out";
            }
          } else {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "1570px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "1700px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "2800px";
                el.style.transition = "height 0.5s ease-in-out";
              } else {
                el.style.height = "1190px";
                el.style.transition = "height 0.5s ease-in-out";
              }
            }
            // 2
            const el2 = document?.getElementById("accordion-collapse-body-2");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
            // 3
            const el3 = document?.getElementById("accordion-collapse-body-3");
            if (el3) {
              el3.style.height = "0px";
              el3.style.transition = "height 0.5s ease-in-out";
            }
            // 4
            const el4 = document?.getElementById("accordion-collapse-body-4");
            if (el4) {
              el4.style.height = "0px";
              el4.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded="true"
          aria-controls="accordion-collapse-body-1"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse1 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse1 ? "circle" : "circle-inactive"}`}
            ></span>
            Why Study in Europe?
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-1"
        className={`${
          collapse1 ? "" : ""
        } h-[2800px] md:h-[1700px] lg:h-[1570px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-1"
      >
        <StudyInEuropeAccrodianBody>
          <p className="my-2 text-gray-500 dark:text-gray-400">
            Studying in Europe offers a wide range of benefits and opportunities
            for international students. Here are some compelling reasons why you
            might consider pursuing your education in Europe:
          </p>
          <div className="bg-blue">
            <h5 className="accrodian-body-span my-6">Academic Excellence:</h5>
            <p className="text-gray-500 mb-2">
              Europe is home to some of the world's oldest and most prestigious
              universities. European institutions consistently rank high in
              global university rankings, ensuring a high standard of education.
            </p>
          </div>
          <h5 className="accrodian-body-span">Diverse Range of Programs:</h5>
          <p className="text-gray-500 mb-2">
            European universities offer a vast array of academic programs and
            courses across various disciplines, allowing you to find the perfect
            fit for your academic interests and career goals.
          </p>
          <h5 className="accrodian-body-span">Multicultural Environment: </h5>
          <p className="text-gray-500 mb-2">
            Europe is known for its cultural diversity. Studying in Europe
            provides an opportunity to interact with people from various
            backgrounds, fostering a global perspective and expanding your
            cultural awareness.
          </p>
          <h5 className="accrodian-body-span">Language Options:</h5>
          <p className="text-gray-500 mb-2">
            While many European countries offer programs in English, studying in
            Europe also provides the chance to learn or improve your language
            skills in various European languages, including French, German,
            Spanish, and more.
          </p>
          <h5 className="accrodian-body-span">Research Opportunities:</h5>
          <p className="text-gray-500 mb-2">
            European universities are at the forefront of research and
            innovation. Many institutions have state-of-the-art research
            facilities, and students often have the opportunity to engage in
            groundbreaking research projects.
          </p>
          <h5 className="accrodian-body-span">Travel Opportunities:</h5>
          <p className="text-gray-500 mb-2">
            Europe's geographical proximity allows for easy travel to
            neighboring countries and diverse cultural experiences. You can
            explore different countries, languages, and cuisines during your
            studies.
          </p>
          <h5 className="accrodian-body-span">Financial Support:</h5>
          <p className="text-gray-500 mb-2">
            Several European countries offer affordable or tuition-free
            education for international students, particularly at the master's
            and doctoral levels. There are also numerous scholarships available.
          </p>
          <h5 className="accrodian-body-span">Quality of Life:</h5>
          <p className="text-gray-500 mb-2">
            European cities consistently rank high in terms of quality of life,
            healthcare, and overall well-being. Europe offers a high standard of
            living and excellent healthcare services.
          </p>
          <h5 className="accrodian-body-span">Work Opportunities:</h5>
          <p className="text-gray-500 mb-2">
            Many European countries provide post-study work opportunities,
            allowing international students to gain practical work experience
            after completing their degrees.
          </p>
          <h5 className="accrodian-body-span">Cultural Heritage:</h5>
          <p className="text-gray-500 mb-2">
            Europe is rich in history, art, architecture, and cultural heritage.
            You can immerse yourself in centuries of history while studying in
            Europe's historic cities.
          </p>
          <h5 className="accrodian-body-span">Networking:</h5>
          <p className="text-gray-500 mb-2">
            European universities often host international conferences,
            seminars, and events, providing opportunities to connect with
            experts and professionals in your field.
          </p>
          <h5 className="accrodian-body-span">Scholarships and Funding:</h5>
          <p className="text-gray-500 mb-2">
            Europe offers numerous scholarships, grants, and financial aid
            options for international students, making education more
            accessible.
          </p>
          <h5 className="accrodian-body-span">Innovation and Technology:</h5>
          <p className="text-gray-500 mb-2">
            European nations are hubs for technology and innovation, making it
            an ideal destination for students interested in STEM (Science,
            Technology, Engineering, and Mathematics) fields.
          </p>
          <p className="text-gray-500 my-2">
            Studying in Europe can be a transformative experience that provides
            not only a top-notch education but also a rich cultural and personal
            journey. Be sure to research specific countries, universities, and
            programs to find the best fit for your academic and personal
            aspirations.
          </p>
        </StudyInEuropeAccrodianBody>
      </div>
      <h2
        id="accordion-collapse-heading-2"
        onClick={() => {
          const t = collapse2;
          setCollapse2(!collapse2);
          setCollapse1(false);
          setCollapse3(false);
          setCollapse4(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-2");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "450px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "450px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "580px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-3");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-4");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-2");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-2"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-2"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse2 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse2 ? "circle" : "circle-inactive"}`}
            ></span>
            Required Documents
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-2"
        className={`${collapse2 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-2"
      >
        <StudyInEuropeAccrodianBody>
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
            <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
              <li className="relative left-[18px] my-2">Proof of enrolment</li>
              <li className="relative left-[18px] my-2">
                Academic Transcripts
              </li>
              <li className="relative left-[18px] my-2">
                English Language Proficiency Test Scores
              </li>
              <li className="relative left-[18px] my-2">
                Statement of Purpose (SOP) or Personal Statement
              </li>
              <li className="relative left-[18px] my-2">
                Curriculum Vitae (CV) or Resume
              </li>
              <li className="relative left-[18px] my-2">
                Letters of Recommendation
              </li>
              <li className="relative left-[18px] my-2">Valid Passport</li>
              <li className="relative left-[18px] my-2">
                Visa Application form and fee
              </li>
              <li className="relative left-[18px] my-2">
                Health and Travel Insurance
              </li>
              <li className="relative left-[18px] my-2">Financial Documents</li>
              <li className="relative left-[18px] my-2">
                Proof of Accommodation
              </li>
              <li className="relative left-[18px] my-2">
                Additional Requirements (Depending on the program or country)
              </li>
            </ul>
          </div>
        </StudyInEuropeAccrodianBody>
      </div>
      <h2
        id="accordion-collapse-heading-3"
        onClick={() => {
          const t = collapse3;
          setCollapse3(!collapse3);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse4(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-3");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "650px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "670px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "1100px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-4");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-3");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-3"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-3"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse3 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse3 ? "circle" : "circle-inactive"}`}
            ></span>
            Application Procedures
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-3"
        className={`${collapse3 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-3"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <h5 className="accrodian-body-span">Intakes</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            The two intakes that are typically offered in Europe are in February
            and July, with a select few universities providing extra intakes in
            September and November. Application submission is advised to begin
            ideally 7-8 months before the admission. The application processing
            time for universities is typically 4-6 weeks.
          </p>
          <h5 className="accrodian-body-span">English Language Requirements</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            English Language requirements vary between institutions. Usually for
            undergraduate courses IELTS 6.0 and for postgraduate courses IELTS
            6.5 is required.
          </p>
          <h5 className="accrodian-body-span">Academic Requirement</h5>
          <h5 className="mb-2 font-semibold">Undergraduate – Bachelors</h5>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">
              GPA 4.75 to 5.0 in HSC / Min 2C in A Level to 3A* in A Level
            </li>
            <li className="relative left-[18px] my-2">
              Few Universities require Math courses in 12th Grade for Business
              and Science programs
            </li>
          </ul>
          <h5 className="my-2 font-semibold">Postgraduate – Masters</h5>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">CGPA 2.50+</li>
            <li className="relative left-[18px] my-2">
              Any work experience in the field will be helpful
            </li>
            <li className="relative left-[18px] my-2">
              GRE or GMAT is not required for Business, Science, and Engineering
              programs
            </li>
          </ul>
          <p className="my-2 text-gray-500 dark:text-gray-400">
            Each institution and country may have its own admission criteria and
            application procedures, so it's crucial to stay informed about the
            specific requirements for your chosen program and location.
          </p>
        </div>
      </div>
      <h2
        id="accordion-collapse-heading-4"
        onClick={() => {
          const t = collapse4;
          setCollapse4(!collapse4);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse3(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-4");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "490px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "490px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "1000px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-3");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-4");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-4"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-4"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse4 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse4 ? "circle" : "circle-inactive"}`}
            ></span>
            Visa Procedure and Fees
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-4"
        className={`${collapse4 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-4"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            The visa procedure for studying in Europe involves several steps,
            and it's important to follow them carefully to ensure a smooth
            application process.
          </p>
          <p className="my-2 text-gray-500 dark:text-gray-400">
            Gather the necessary documents for your visa application. Pay the
            student visa application fee, which can be done online or at a New
            Zealand visa application center in your home country. Submit your
            visa application online through the Immigration Europe website. If
            applying offline, complete the paper application form and submit it
            to the nearest Europe Visa Application Center (VAC) or Immigration
            Europe office in your home country.
          </p>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Some applicants may be required to provide biometric information,
            such as fingerprints and a photograph, at a designated application
            center.
          </p>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            In some cases, Europe Immigration may request an interview as part
            of the visa application process.
          </p>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            If you applied through a Europe Visa Application Center (VAC), you
            can collect your visa from there. If you apply online, you may
            receive an eVisa, which you can print.
          </p>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            It's important to visit the official website of Europe Immigration
            for the most recent information and instructions on the student visa
            application process because visa rules and processes are subject to
            change. Consider applying for your student visa well enough in
            advance to allow for processing before the start of your study.
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudyInEuropeAccrodian;
