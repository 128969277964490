import React from "react";
import PageLayout from "../../components/Common/PageLayout/PageLayout";
import linksList from "../../utils/linksList";
import PrivacyPolicyAccrodian from "../../components/Others/PrivacyPolicyAccrodian";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Russell Global Consultancy | Privacy Policy</title>
        <meta
          name="description"
          content="Russell Global Consultancy Privacy Policy Page"
        />
        <link
          rel="canonical"
          href="https://russellglobalconsultancy.com/privacy-policy"
        />
      </Helmet>
      <PageLayout
        header={"PRIVACY POLICY"}
        subHeader={"Data Protection, Copyright, etc"}
        title={"Privacy Policy"}
        subTitle={
          "Next to its people, Russell Global Consultancy regards information as its most important asset."
        }
        links={linksList}
        layoutClass={"privacy"}
      >
        <PrivacyPolicyAccrodian />
      </PageLayout>
    </div>
  );
};

export default PrivacyPolicy;
