import React from "react";
import "../../Services/ScholarshipAssistanceAccrodian/ScholarshipAssistanceAccrodian.css";

const AboutUsAccrodian = () => {
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <h5 className="font-semibold text-2xl mt-4 mb-2">Overview</h5>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        The Russell Global Consultancy is located in the center of Bangladesh's
        capital city, Dhaka. We are one of Bangladesh's leading study abroad
        organisations, putting students in universities and colleges throughout
        the globe.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        We started as a tiny consultancy business and have since achieved
        extraordinary success. The goodwill we were able to build was made
        possible by our devotion and perseverance. We were able to accomplish
        this level of success because of the use of the most advanced
        communication methods, extensive international travel, attendance at
        some of the world's top educational exhibits and seminars, contact
        management, and the maintenance of global alliances.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        To make sure that students receive the greatest and most suitable
        counsel, we closely collaborate with institutions and students. Our goal
        is to make sure that every student receives appropriate guidance, and
        this, together with our in-depth understanding of education and our
        strong connections with universities and colleges, ensures that we
        always secure the best placement for our students. Our strong
        connections with universities enable us to provide outstanding services
        to our students. We serve as a single point of contact for international
        education since we handle all aspects of the application process and
        visa procedures.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        By reducing the educational disparities between Bangladesh and developed
        nations and fostering cooperative understanding between cultures, we
        want to solve the educational challenges of the contemporary global
        period.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        We place a high value on maintaining our good name, and we are proud of
        the recommendations we have gotten from former customers, many of whom
        come back to us for more services.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        We have been successful in the past and anticipate continued success in
        the years to come. So that you may enjoy our excellent services, we
        encourage you to join us. If this website does not include the
        information you are searching for, contact us by phone or email and a
        member of our helpful staff will help you.
      </p>
    </div>
  );
};

export default AboutUsAccrodian;
