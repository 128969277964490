import React, { useState } from "react";
import "./StudyChinaAccrodian.css";
import StudyInChinaAccrodianBody from "./StudyInChinaAccrodianBody";

const StudyInChinaAccrodian = () => {
  const [collapse1, setCollapse1] = useState(true);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [collapse4, setCollapse4] = useState(false);
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <h2
        id="accordion-collapse-heading-1"
        onClick={() => {
          const t = collapse1;
          setCollapse1(!collapse1);
          setCollapse2(false);
          setCollapse3(false);
          setCollapse4(false);
          if (t) {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              el.style.height = "0px";
              el.style.transition = "height 0.5s ease-in-out";
            }
          } else {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "1190px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "1380px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "2200px";
                el.style.transition = "height 0.5s ease-in-out";
              } else {
                el.style.height = "1190px";
                el.style.transition = "height 0.5s ease-in-out";
              }
            }
            // 2
            const el2 = document?.getElementById("accordion-collapse-body-2");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
            // 3
            const el3 = document?.getElementById("accordion-collapse-body-3");
            if (el3) {
              el3.style.height = "0px";
              el3.style.transition = "height 0.5s ease-in-out";
            }
            // 4
            const el4 = document?.getElementById("accordion-collapse-body-4");
            if (el4) {
              el4.style.height = "0px";
              el4.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded="true"
          aria-controls="accordion-collapse-body-1"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse1 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse1 ? "circle" : "circle-inactive"}`}
            ></span>
            Why Study in China?
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-1"
        className={`${
          collapse1 ? "" : ""
        } h-[2200px] md:h-[1380px] lg:h-[1190px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-1"
      >
        <StudyInChinaAccrodianBody>
          <p className="my-2 text-gray-500 dark:text-gray-400">
            Studying in China can be an attractive option for international
            students due to several compelling reasons:
          </p>
          <div className="bg-blue">
            <h5 className="accrodian-body-span my-6">
              Growing Academic Excellence:
            </h5>
            <p className="text-gray-500 mb-2">
              China has made significant investments in its higher education
              system, with several universities consistently ranking among the
              top in global rankings. This has led to an increase in the quality
              of education and research opportunities.
            </p>
          </div>
          <h5 className="accrodian-body-span">Wide Range of Programs:</h5>
          <p className="text-gray-500 mb-2">
            Chinese universities offer a diverse array of programs across
            various disciplines, providing options for students with different
            academic interests and career goals.
          </p>
          <h5 className="accrodian-body-span">Cost-Effective Education: </h5>
          <p className="text-gray-500 mb-2">
            Tuition fees and living costs in China are often more affordable
            compared to many Western countries, making it an economical choice
            for international students.
          </p>
          <h5 className="accrodian-body-span">Scholarship Opportunities:</h5>
          <p className="text-gray-500 mb-2">
            The Chinese government and various Chinese universities offer a
            range of scholarships and financial aid options for international
            students, including the Chinese Government Scholarship, Confucius
            Institute Scholarship, and university-specific scholarships.
          </p>
          <h5 className="accrodian-body-span">Cultural Experience:</h5>
          <p className="text-gray-500 mb-2">
            The Chinese government and various Chinese universities offer a
            range of scholarships and financial aid options for international
            students, including the Chinese Government Scholarship, Confucius
            Institute Scholarship, and university-specific scholarships.
          </p>
          <h5 className="accrodian-body-span">Language Learning:</h5>
          <p className="text-gray-500 mb-2">
            Learning Chinese (Mandarin) is an asset in today's global job
            market. Studying in China allows you to improve your Chinese
            language skills, which can be valuable for future career
            opportunities.
          </p>
          <h5 className="accrodian-body-span">Global Perspective:</h5>
          <p className="text-gray-500 mb-2">
            China's global influence is on the rise, and studying there provides
            a unique vantage point to understand the country's role in global
            politics, economics, and culture.
          </p>
          <h5 className="accrodian-body-span">Innovation and Technology:</h5>
          <p className="text-gray-500 mb-2">
            China is a leader in technology and innovation, making it an
            excellent destination for students interested in STEM (Science,
            Technology, Engineering, and Mathematics) fields.
          </p>
          <h5 className="accrodian-body-span">Research Opportunities:</h5>
          <p className="text-gray-500 mb-2">
            Chinese universities are engaged in cutting-edge research across
            various disciplines. International students often have the
            opportunity to participate in research projects.
          </p>
          <h5 className="accrodian-body-span">Work Opportunities:</h5>
          <p className="text-gray-500 mb-2">
            Some international students may have the opportunity to work
            part-time during their studies and explore internship or employment
            opportunities in China.
          </p>
        </StudyInChinaAccrodianBody>
      </div>
      <h2
        id="accordion-collapse-heading-2"
        onClick={() => {
          const t = collapse2;
          setCollapse2(!collapse2);
          setCollapse1(false);
          setCollapse3(false);
          setCollapse4(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-2");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "390px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "390px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "520px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-3");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-4");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-2");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-2"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-2"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse2 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse2 ? "circle" : "circle-inactive"}`}
            ></span>
            Required Documents
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-2"
        className={`${collapse2 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-2"
      >
        <StudyInChinaAccrodianBody>
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
            <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
              <li className="relative left-[18px] my-2">Proof of enrolment</li>
              <li className="relative left-[18px] my-2">
                Academic Transcripts
              </li>
              <li className="relative left-[18px] my-2">
                English Language Proficiency Test Scores
              </li>
              <li className="relative left-[18px] my-2">
                Statement of Purpose (SOP) or Personal Statement
              </li>
              <li className="relative left-[18px] my-2">
                Curriculum Vitae (CV) or Resume
              </li>
              <li className="relative left-[18px] my-2">
                Letters of Recommendation
              </li>
              <li className="relative left-[18px] my-2">Valid Passport</li>
              <li className="relative left-[18px] my-2">
                Visa Application form and fee
              </li>
              <li className="relative left-[18px] my-2">Financial Documents</li>
              <li className="relative left-[18px] my-2">
                Medical and Police Clearance Certificates
              </li>
            </ul>
          </div>
        </StudyInChinaAccrodianBody>
      </div>
      <h2
        id="accordion-collapse-heading-3"
        onClick={() => {
          const t = collapse3;
          setCollapse3(!collapse3);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse4(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-3");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "710px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "710px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "1040px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-4");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-3");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-3"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-3"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse3 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse3 ? "circle" : "circle-inactive"}`}
            ></span>
            Application Procedures
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-3"
        className={`${collapse3 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-3"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <h5 className="accrodian-body-span">Intakes</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            The main intakes offered by all Universities are August/September
            and March. Few universities offer May/June intakes also.
          </p>
          <h5 className="accrodian-body-span">English Language Requirements</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            IELTS is not mandatory for all the programs except Masters program.
            It can be waived if you have an English Medium background. Also, the
            requirement depends on the Institutions and Programs.
          </p>
          <h5 className="accrodian-body-span">Academic Requirement</h5>
          <h5 className="mb-2 font-semibold">Diploma - Advanced Diploma</h5>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">
              GPA 3.0 to 5.0 in HSC / Min 4-5C in O Level with C in Math
            </li>
            <li className="relative left-[18px] my-2">
              Few Institutions accept GED and few require 10th Grade results
              with GED
            </li>
          </ul>
          <h5 className="mb-2 font-semibold">Undergraduate – Bachelors</h5>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">
              GPA 3.5 to 5.0 in HSC / Min 2C in A Level to 3A* in A Level
            </li>
            <li className="relative left-[18px] my-2">
              Few Universities require Math courses in 12th Grade for Business
              and Science programs
            </li>
          </ul>
          <h5 className="my-2 font-semibold">Postgraduate – Masters</h5>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">CGPA 2.50+</li>
            <li className="relative left-[18px] my-2">CGPA 2.5+/-</li>
            <li className="relative left-[18px] my-2">
              GRE or GMAT is not required.
            </li>
            <li className="relative left-[18px] my-2">
              Work experience is not mandatory but may be required
            </li>
          </ul>
        </div>
      </div>
      <h2
        id="accordion-collapse-heading-4"
        onClick={() => {
          const t = collapse4;
          setCollapse4(!collapse4);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse3(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-4");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "490px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "490px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "710px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-3");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-4");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-4"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-4"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse4 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse4 ? "circle" : "circle-inactive"}`}
            ></span>
            Visa Procedure
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-4"
        className={`${collapse4 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-4"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            The admission notice JW doesn't ensure a visa. The local embassy
            will verify the skills of the student besides the documents through
            an intensive interview for students to apply for visas to enter
            China. In order to acquire a student visa from the local Chinese
            embassy, students need to prepare full documents according to the
            Embassy's requirements and need to get ready for the interview at
            the Embassy.
          </p>
          <p className="my-2 text-gray-500 dark:text-gray-400">
            <strong>Russell Global Consultancy</strong> assists the students in
            preparing all the documents for the application for a student visa &
            prepares them well before going to the embassy for the interview.
            The finest interview is the key to getting a Chinese student visa.
            Through a satisfactory interview, students will get the entrance
            student visa (X1/X2) which needs to be extended within a month after
            arrival.
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudyInChinaAccrodian;
