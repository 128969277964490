import React from "react";
import "./RecruitmentInquiryLayout.css";

const RecruitmentInquiryLayout = ({
  header,
  subHeader,
  title,
  subTitle1,
  subTitle2,
  children,
  links,
  layoutClass,
}) => {
  return (
    <div className="min-h-full">
      <div className={`layout ${layoutClass}`}>
        {/* layout header  */}
        <div className="layout-header-container">
          <h2 className="text-white text-3xl font-bold text-center pb-4 relative">
            {header}
          </h2>
          <span className="font-semibold text-center inline-block">
            {subHeader}
          </span>
        </div>
      </div>
      {/* layout body  */}
      <div className="py-16">
        <div className="flex flex-col justify-between items-center">
          <h2 className="text-[#002147] text-2xl text-center lg:text-5xl p-[2px] font-bold w-full lg:w-1/2 me-8">
            {title}
          </h2>
          <div className="w-full lg:w-1/2">
            <p className="w-full pt-3 text-center lg:text-lg font-light">
              {subTitle1}
            </p>
            {/* <p className="w-full text-center lg:text-lg font-light">
              {subTitle2}
            </p> */}
          </div>
        </div>
        {/* main content  */}
        <div className="w-full px-6 md:px-12 lg:px-32 pt-20">
          {/* quick links  */}
          {/* <div>
            <QuickLinks links={links} />
          </div> */}
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentInquiryLayout;
