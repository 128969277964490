import React from "react";

const OurServicesAccrodian = () => {
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <h5 className="font-semibold text-2xl mt-4 mb-2">Study Services</h5>
      <p className="text-gray-500 text-[1.1rem]">
        The decision of which university or college to attend has a significant
        influence on a student's life. Today's students must face the grim
        reality of trying to locate a suitable course at a reasonable price from
        a reputable institution or college. The secret to navigating the
        emotional and financial considerations surrounding the
        college/university choosing process is careful strategic preparation.
        Here, Russell Global Consultancy Education & Recruitment Consultants may
        support students in making wise and fruitful decisions. Russell Global
        Consultancy Education & Recruitment Consultants makes sure that students
        successfully complete their long-cherished dreams of studying abroad.
        Counselors with extensive training and expertise are available to assist
        students as effectively as feasible.
      </p>
      <p className="text-gray-500 text-[1.1rem]">
        Our Counsellors are very careful in selecting students for institutions
        according to the criteria specified by the respective institutions. When
        a student visits, one of our counselors conducts an interview with him
        or her to determine the student's eligibility and to evaluate the
        student's grades and prior academic and professional achievement. Due to
        the fact that we only accept students who are flawless in every way, we
        have a very high visa success rate.
      </p>
      <h5 className="font-semibold text-2xl mt-4 mb-2">Recruitment Services</h5>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        Russell Global Consultancy is a leading foreign recruitment enterprise
        bringing top international skilled workers worldwide.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        We provide client companies and candidates with integrity, personal
        attention, and confidentiality at the highest professional standards.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        We attract and present qualified international candidates to worldwide
        employers in record time because of our extensive experience, knowledge,
        and unique approach.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        We welcome the opportunity to present employers with highly qualified
        international talent in the industries we serve.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        Our company is a top online recruiting company that links global
        companies with international job seekers. We are committed to creating
        lasting strategic partnerships with both client organisations and
        prospects. Our guiding values of sincerity, integrity, and discretion
        are scrupulously followed.
      </p>
      <p className="my-2 text-[1.1rem] text-gray-500 dark:text-gray-400">
        In order to assist job seekers in finding the most current employment
        possibilities that match their professional experience and career goals,
        we provide the most powerful career-building services.
      </p>
      <p className="my-6 text-[1.1rem] font-normal text-gray-900 dark:text-gray-400 italic">
        If you would like to promote your institute and Company in Bangladesh,
        please email us at{" "}
        <a
          className="underline underline-offset-1"
          href="mailto:russellglobalconsultancy@gmail.com"
        >
          russellglobalconsultancy@gmail.com
        </a>
        . We are currently acting as the authorized representatives of many
        well-known colleges, universities, and companies.
      </p>
    </div>
  );
};

export default OurServicesAccrodian;
