import React, { useState } from "react";
import StudyInAustraliaAccrodianBody from "./StudyInAustraliaAccrodianBody";
import "./StudyAustarliaAccrodian.css";

const StudyInAustraliaAccrodian = () => {
  const [collapse1, setCollapse1] = useState(true);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [collapse4, setCollapse4] = useState(false);
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <h2
        id="accordion-collapse-heading-1"
        onClick={() => {
          const t = collapse1;
          setCollapse1(!collapse1);
          setCollapse2(false);
          setCollapse3(false);
          setCollapse4(false);
          if (t) {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              el.style.height = "0px";
              el.style.transition = "height 0.5s ease-in-out";
            }
          } else {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "1190px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "1380px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "2200px";
                el.style.transition = "height 0.5s ease-in-out";
              } else {
                el.style.height = "1190px";
                el.style.transition = "height 0.5s ease-in-out";
              }
            }
            // 2
            const el2 = document?.getElementById("accordion-collapse-body-2");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
            // 3
            const el3 = document?.getElementById("accordion-collapse-body-3");
            if (el3) {
              el3.style.height = "0px";
              el3.style.transition = "height 0.5s ease-in-out";
            }
            // 4
            const el4 = document?.getElementById("accordion-collapse-body-4");
            if (el4) {
              el4.style.height = "0px";
              el4.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded="true"
          aria-controls="accordion-collapse-body-1"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse1 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse1 ? "circle" : "circle-inactive"}`}
            ></span>
            Why Study in AUSTRALIA?
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-1"
        className={`${
          collapse1 ? "" : ""
        } h-[2200px] md:h-[1380px] lg:h-[1190px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-1"
      >
        <StudyInAustraliaAccrodianBody>
          <p className="my-2 text-gray-500 dark:text-gray-400">
            Studying in Australia can be an attractive option for international
            students for several reasons:
          </p>
          <div className="bg-blue">
            <h5 className="accrodian-body-span my-6">
              High-Quality Education:
            </h5>
            <p className="text-gray-500 mb-2">
              Australia is known for its world-class education system and high
              academic standards. The country has a strong commitment to quality
              assurance, with strict regulations and accreditation processes in
              place to ensure the excellence of its institutions.
            </p>
          </div>
          <h5 className="accrodian-body-span">Diverse Range of Courses:</h5>
          <p className="text-gray-500 mb-2">
            Australian universities and colleges offer a wide variety of
            programs and courses, catering to diverse interests and career
            goals. Whether you're interested in science, arts, engineering,
            business, or any other field, you'll likely find a program that
            suits your needs.
          </p>
          <h5 className="accrodian-body-span">Research Opportunities: </h5>
          <p className="text-gray-500 mb-2">
            Australia is a leader in various fields of research, and many of its
            universities are renowned for their research contributions. If
            you're interested in pursuing research, Australia provides numerous
            opportunities and resources.
          </p>
          <h5 className="accrodian-body-span">High Standard of Living: </h5>
          <p className="text-gray-500 mb-2">
            Australia consistently ranks high in terms of quality of life,
            safety, and healthcare. The country offers a high standard of
            living, making it an attractive destination for international
            students looking for a comfortable and secure environment.
          </p>
          <h5 className="accrodian-body-span">Work Opportunities: </h5>
          <p className="text-gray-500 mb-2">
            Australia has a strong job market and offers various work
            opportunities for international students, both during their studies
            and after graduation. The Post-Study Work Visa (PSWV) allows
            graduates to work in Australia for a period after completing their
            studies, providing valuable work experience.
          </p>
          <h5 className="accrodian-body-span">Natural Beauty: </h5>
          <p className="text-gray-500 mb-2">
            Australia is known for its stunning landscapes, including beautiful
            beaches, lush rainforests, and unique wildlife. It's an ideal
            destination for those who enjoy outdoor activities and exploring
            nature.
          </p>
          <h5 className="accrodian-body-span">Safety and Security: </h5>
          <p className="text-gray-500 mb-2">
            Australia is considered one of the safest countries in the world,
            with low crime rates and a stable political environment, which can
            provide peace of mind to international students and their families.
          </p>
          <h5 className="accrodian-body-span">
            English Language Proficiency:{" "}
          </h5>
          <p className="text-gray-500 mb-2">
            For students looking to improve their English language skills,
            Australia is an excellent choice because English is the primary
            language of instruction. This provides an immersive environment for
            language learning.
          </p>
          <h5 className="accrodian-body-span">Scholarship Opportunities:</h5>
          <p className="text-gray-500 mb-2">
            Australian universities offer various scholarships and financial aid
            options to international students, making it more affordable to
            pursue higher education in the country.
          </p>
          <h5 className="accrodian-body-span">
            Cultural and Social Experiences:{" "}
          </h5>
          <p className="text-gray-500 mb-2">
            Australia hosts numerous cultural events, festivals, and social
            activities, providing international students with opportunities to
            engage with the local culture and make friends from around the
            world.
          </p>
        </StudyInAustraliaAccrodianBody>
      </div>
      <h2
        id="accordion-collapse-heading-2"
        onClick={() => {
          const t = collapse2;
          setCollapse2(!collapse2);
          setCollapse1(false);
          setCollapse3(false);
          setCollapse4(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-2");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "780px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "780px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "1380px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-3");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-4");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-2"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-2"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse2 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse2 ? "circle" : "circle-inactive"}`}
            ></span>
            Required Documents
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-2"
        className={`${collapse2 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-2"
      >
        <StudyInAustraliaAccrodianBody>
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
            <p className="mb-2">
              The specific required documents for studying in Australia can vary
              depending on the level of study (e.g., undergraduate, graduate,
              research), the institution you're applying to, and your country of
              origin. However, here is a general list of common documents you
              may need when applying for admission to an Australian educational
              institution:
            </p>
            <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
              <li className="relative left-[18px] my-2">Proof of enrolment</li>
              <li className="relative left-[18px] my-2">
                Academic Transcripts
              </li>
              <li className="relative left-[18px] my-2">
                English Language Proficiency Test Scores
              </li>
              <li className="relative left-[18px] my-2">
                Statement of Purpose (SOP) or Personal Statement
              </li>
              <li className="relative left-[18px] my-2">
                Curriculum Vitae (CV) or Resume
              </li>
              <li className="relative left-[18px] my-2">
                Letters of Recommendation
              </li>
              <li className="relative left-[18px] my-2">Valid Passport</li>
              <li className="relative left-[18px] my-2">
                Visa Application form and fee
              </li>
              <li className="relative left-[18px] my-2">
                Health and Travel Insurance
              </li>
              <li className="relative left-[18px] my-2">Financial Documents</li>
              <li className="relative left-[18px] my-2">
                Genuine Temporary Entrant (GTE) Statement
              </li>
              <li className="relative left-[18px] my-2">
                Medical and Police Clearance Certificates
              </li>
              <li className="relative left-[18px] my-2">
                Portfolio or Supplementary Materials
              </li>
              <li className="relative left-[18px] my-2">
                Proof of Work Experience (if applicable)
              </li>
            </ul>
            <p className="text-gray-500 my-2">
              Please note that these are general requirements, and the specific
              documents and application process can vary by institution and
              course. It's essential to check the official website of the
              Australian institution you plan to apply to for the most
              up-to-date and specific information regarding their admission
              requirements and application process. Additionally, always verify
              visa requirements with the Australian Department of Home Affairs
              to ensure you have the most accurate and current information.
            </p>
          </div>
        </StudyInAustraliaAccrodianBody>
      </div>
      <h2
        id="accordion-collapse-heading-3"
        onClick={() => {
          const t = collapse3;
          setCollapse3(!collapse3);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse4(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-3");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "585px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "585px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "1040px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-4");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-3");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-3"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-3"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse3 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse3 ? "circle" : "circle-inactive"}`}
            ></span>
            Application Procedures
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-3"
        className={`${collapse3 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-3"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <h5 className="accrodian-body-span">Intakes</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            The two intakes that are typically offered in Australia are in
            February and July, with a select few universities providing extra
            intakes in September and November. Application submission is advised
            to begin ideally 7-8 months before the admission. The application
            processing time for universities is typically 4-6 weeks.
          </p>
          <h5 className="accrodian-body-span">English Language Requirements</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            English Language requirements vary between institutions. Usually for
            undergraduate courses IELTS 6.0 and for postgraduate courses IELTS
            6.5 is required.
          </p>
          <h5 className="accrodian-body-span">Academic Requirement</h5>
          <h5 className="mb-2 font-semibold">Undergraduate – Bachelors</h5>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">
              A good academic record in High School Certificate or 12 years of
              schooling
            </li>
            <li className="relative left-[18px] my-2">
              A good academic record in High School Certificate or 12 years of
              schooling
            </li>
          </ul>
          <h5 className="my-2 font-semibold">Postgraduate – Masters</h5>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">
              A good academic record in Bachelors Degree from a recognized
              university is necessary
            </li>
            <li className="relative left-[18px] my-2">
              Any work experience in the field will be helpful
            </li>
            <li className="relative left-[18px] my-2">
              For MBA programs at some Universities, students need to have work
              experience along with their Bachelor's Degree
            </li>
          </ul>
        </div>
      </div>
      <h2
        id="accordion-collapse-heading-4"
        onClick={() => {
          const t = collapse4;
          setCollapse4(!collapse4);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse3(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-4");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "490px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "490px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "710px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-3");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-4");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-4"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-4"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse4 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse4 ? "circle" : "circle-inactive"}`}
            ></span>
            Visa Procedure
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-4"
        className={`${collapse4 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-4"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            The kind of curriculum or study you plan to pursue, as well as the
            length of your intended stay in Australia, will determine the type
            of visa you require. The Department of Immigration and Border
            Protection (DIBP) website of the Australian government has further
            information about each type of visa.
          </p>
          <h5 className="accrodian-body-span">Apply Online</h5>
          <p className="my-2 text-gray-500 dark:text-gray-400">
            To finish and submit your student visa application, you must first
            create an account on DIBP's online application system, ImmiAccount.
            Once you have filed your visa, you may check the status of it using
            ImmiAccount.
          </p>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Your application must be turned in no later than six weeks before
            the commencement of your course and no sooner than 12 weeks.
          </p>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            The Australian Government will assign your application a special
            Transaction Reference Number (TRN). The progress of your application
            may be checked online at{" "}
            <a
              className="underline underline-offset-1"
              href="https://www.homeaffairs.gov.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.homeaffairs.gov.au/
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudyInAustraliaAccrodian;
