import React from "react";
import FadeSlider from "./FadeSlider";
import BannerBottom from "./BannerBottom";

const HeroBanner = () => {
  return (
    <>
      <FadeSlider />
      <BannerBottom />
    </>
  );
};

export default HeroBanner;
