import React from "react";
import PageLayout from "../../components/Common/PageLayout/PageLayout";
import linksList from "../../utils/linksList";
import OurMissionAccrodian from "../../components/Overviews/OurMissionAccrodian/OurMissionAccrodian";
import { Helmet } from "react-helmet";

const OurMission = () => {
  return (
    <div>
      <Helmet>
        <title>Russell Global Consultancy | Our Mission</title>
        <meta
          name="description"
          content="Russell Global Consultancy Our Mission Page"
        />
        <link
          rel="canonical"
          href="https://russellglobalconsultancy.com/overview/mission"
        />
      </Helmet>
      <PageLayout
        header={"MISSION AND VISION"}
        subHeader={"Your trusted partner in studying abroad"}
        title={"Mission and Vision"}
        subTitle={
          "Our mission is to bridging the gap between education differences between Bangladesh and developed countries."
        }
        links={linksList}
        layoutclassName={"mission"}
      >
        <OurMissionAccrodian />
      </PageLayout>
    </div>
  );
};

export default OurMission;
