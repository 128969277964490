import React from "react";
import counseling from "../../../assets/icons/hww-counselling.jpg";
import application from "../../../assets/icons/hww-visaapplication.jpg";
import depature from "../../../assets/icons/hww-departure.jpg";

const HowWeWork = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center px-6 md:px-12 lg:px-32 pb-28 py-6 bg-gray-100">
      <h2 className="text-2xl lg:text-5xl text-[#002147] font-bold mb-4 mt-16">
        How We Work?
      </h2>
      <p className="w-full md:w-1/2">
        It is very easy to apply through Russell Global Consultancy. It starts
        from career counselling and ends in pre-departure orientation.
      </p>
      <ol className="mb-16 relative top-16 left-[50px] md:left-0 text-gray-500 border-l border-s-2 border-gray-500 dark:border-gray-700 dark:text-gray-400">
        <li className="mb-20 mt-0 ml-6">
          <p className="absolute right-[255px] top-[5px] text-white bg-[#002147] py-[4px] px-[20px]">
            Step1
          </p>
          <span className="absolute flex items-center justify-center w-12 h-10 bg-green-200 rounded-full -left-6 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
            <img src={counseling} alt="" srcSet="" height={60} width={60} />
          </span>
          <h3 className="font-medium leading-tight ms-4">Initial Assesment</h3>
          {/* <p className="text-sm ms-4">Step details here</p> */}
        </li>
        <li className="mb-20 ml-6">
          <p className="absolute right-[255px] top-[103px] text-white bg-[#002147] py-[4px] px-[20px]">
            Step2
          </p>
          <span className="absolute flex items-center justify-center w-12 h-10 bg-gray-100 rounded-full -left-6 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
            <img src={application} alt="" srcSet="" height={60} width={60} />
          </span>
          <h3 className="font-medium leading-tight ms-4">Re Assesment</h3>
          {/* <p className="text-sm ms-4">Step details here</p> */}
        </li>
        <li className="mb-20 ml-6">
          <p className="absolute right-[255px] top-[203px] text-white bg-[#002147] py-[4px] px-[20px]">
            Step3
          </p>
          <span className="absolute flex items-center justify-center w-12 h-10 bg-gray-100 rounded-full -left-6 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
            <img src={application} alt="" srcSet="" height={60} width={60} />
          </span>
          <h3 className="font-medium leading-tight ms-4">
            Apply To University
          </h3>
          {/* <p className="text-sm ms-4">Step details here</p> */}
        </li>
        <li className="mb-20 ml-6">
          <p className="absolute right-[255px] top-[303px] text-white bg-[#002147] py-[4px] px-[20px]">
            Step4
          </p>
          <span className="absolute flex items-center justify-center w-12 h-10 bg-gray-100 rounded-full -left-6 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
            <img src={application} alt="" srcSet="" height={60} width={60} />
          </span>
          <h3 className="font-medium leading-tight ms-4">
            Enrolment Confrimation
          </h3>
          {/* <p className="text-sm ms-4">Step details here</p> */}
        </li>
        <li className="ml-6">
          <p className="absolute right-[255px] top-[404px] text-white bg-[#002147] py-[4px] px-[20px]">
            Step5
          </p>
          <span className="absolute flex items-center justify-center w-12 h-10 bg-gray-100 rounded-full -left-6 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
            <img src={depature} alt="" srcSet="" height={60} width={60} />
          </span>
          <h3 className="font-medium leading-tight ms-4">Visa Process</h3>
          {/* <p className="text-sm ms-4">Step details here</p> */}
        </li>
      </ol>
    </div>
  );
};

export default HowWeWork;
