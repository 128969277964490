import React from "react";
import PageLayout from "../../components/Common/PageLayout/PageLayout";
import linksList from "../../utils/linksList";
import StudyInUkAccrodian from "../../components/Services/StudyInUkAccrodian/StudyInUkAccrodian";
import { Helmet } from "react-helmet";

const StudyInUk = () => {
  return (
    <div>
      <Helmet>
        <title>Russell Global Consultancy | Study In UK</title>
        <meta
          name="description"
          content="Russell Global Consultancy Study In UK Page"
        />
        <link
          rel="canonical"
          href="https://russellglobalconsultancy.com/services/study-uk"
        />
      </Helmet>
      <PageLayout
        header={"STUDY IN UK"}
        subHeader={"Thinking about studying in UK from Bangladesh?"}
        title={"STUDY IN UK"}
        subTitle={
          "Thinking about studying in UK from Bangladesh and you need help in selecting a program, the right university? Russell Global Consultancy can help you! Process your application through the British Council Trained Agent Consultants and British Graduate."
        }
        links={linksList}
      >
        <StudyInUkAccrodian />
      </PageLayout>
    </div>
  );
};

export default StudyInUk;
