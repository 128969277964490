import React from "react";
import PageLayout from "../../components/Common/PageLayout/PageLayout";
import linksList from "../../utils/linksList";
import OurServicesAccrodian from "../../components/Overviews/OurServicesAccrodian/OurServicesAccrodian";
import { Helmet } from "react-helmet";

const OurServices = () => {
  return (
    <div>
      <Helmet>
        <title>Russell Global Consultancy | Our Services</title>
        <meta
          name="description"
          content="Russell Global Consultancy Our Services Page"
        />
        <link
          rel="canonical"
          href="https://russellglobalconsultancy.com/overview/our-services"
        />
      </Helmet>
      <PageLayout
        header={"OUR SERVICES"}
        subHeader={"Business is our Signature"}
        title={"Our Services"}
        subTitle={
          "Russell Global Consultancy Education & Recruitment Consultants is one of the best education consultants in Dhaka, Bangladesh."
        }
        links={linksList}
      >
        <OurServicesAccrodian />
      </PageLayout>
    </div>
  );
};

export default OurServices;
