import React from "react";
import TestimonialCard from "./TestimonialCard";
import testimonial1 from "../../../assets/testimonial/review1.jpg";
import testimonial2 from "../../../assets/testimonial/review2.jpg";
import testimonial3 from "../../../assets/testimonial/review3.jpg";

const reviews = [
  {
    name: "Rakibul Hasan",
    designation: "RMIT University",
    review:
      "People nowadays don't believe in agencies for going abroad. But the fact is, yes there are agencies whom you could trust without any second thought... ",
    image: testimonial1,
  },
  {
    name: "MD Musfiqur Rahman",
    designation: "Edith cowan university",
    review:
      " One of the friendliest and reliable place to seek advice for. The cordiality in acceptance and professionalism in processing file is appreciatable... ",
    image: testimonial3,
  },
  {
    name: "MD Arif Hasan",
    designation: "Lappeenranta-Lahti University of Technology LUT",
    review:
      " One of the friendliest and reliable place to seek advice for. The cordiality in acceptance and professionalism in processing file is appreciatable... ",
    image: testimonial2,
  },
];

const Testimonial = () => {
  return (
    <div className="my-14">
      <div className="my-4 flex flex-col justify-center items-center">
        <h2 className="text-[#002147] text-xl lg:text-4xl font-bold text-center mb-6">
          What Our Students Say?
        </h2>
        <p className="text-center w-full px-16 md:px-28 text-gray-400 mb-6">
          We’ve chosen a selection of student testimonials to give you a better
          insight about us, our honesty, transparency and our devotion towards
          our students.
        </p>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 p-2">
        {reviews?.map((r, i) => (
          <div className={`w-full flex justify-center`} key={i}>
            <TestimonialCard
              name={r?.name}
              designation={r?.designation}
              review={r?.review}
              image={r?.image}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
