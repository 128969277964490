import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div className="about-us-container px-0 md:px-12 lg:px-32 bg-[#F8FAFB]">
      <div className="w-full px-4 md:px-0 md:w-1/2 h-full flex flex-col justify-center bg-[#F8FAFB] py-28">
        <div className="w-full bg-[#F8FAFB] mb-12">
          <h2 className="text-2xl lg:text-5xl font-extrabold mb-3 text-[#002147]">
            Why we are different?
          </h2>
          <h4 className="text-xl font-semibold mb-4">
            What makes us different makes us better.
          </h4>
          <p>
            We are distinctive in the quality of our services and stand out of
            the crowd. Unlike other consultancy firms in Bangladesh, we really
            care for our students. We always strive to give the best possible
            guidelines and solutions that a student may require. Our experienced
            consultants and in house lawyer are always at hand to prepare your
            visa application documents in a perfect way.
          </p>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-[3.25rem] w-full bg-[#F8FAFB]">
          <div className="flex flex-col justify-center items-center">
            <CircularProgressbar
              value={100}
              text={`${100}%`}
              styles={buildStyles({
                pathColor: "#002147",
                textColor: "#002147",
              })}
            />
            <h3 className="mt-2 text-lg">Free Advice</h3>
          </div>
          <div className="flex flex-col justify-center items-center">
            <CircularProgressbar
              value={100}
              text={`${100}%`}
              styles={buildStyles({
                pathColor: "#002147",
                textColor: "#002147",
              })}
            />
            <h3 className="mt-2 text-lg">Satisfied Clients</h3>
          </div>
          <div className="flex flex-col justify-center items-center">
            <CircularProgressbar
              value={90}
              text={`${90}%`}
              styles={buildStyles({
                pathColor: "#002147",
                textColor: "#002147",
              })}
            />
            <h3 className="mt-2 text-lg">Visa Success</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
