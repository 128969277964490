import React from "react";
import "./ScholarshipAssistanceAccrodian.css";

const ScholarshipAssistanceAccrodian = () => {
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <p className="my-2 text-lg text-gray-500 dark:text-gray-400">
        Russell Global Consultancy helps find scholarships for students
        interested in studying in Australia, New Zealand, the UK, China, and
        Europe.
      </p>
      <p className="my-2 text-lg text-gray-500 dark:text-gray-400">
        Russell Global Consultancy’ counsellors assist students in obtaining
        university scholarships for deserving applicants whose academic
        performance are outstanding. The Universities whom we work with are
        always looking to recruit academically meritorious students and offer
        substantial scholarship offers.
      </p>
      <h5 className="font-semibold my-2">How we can help:</h5>
      <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
        <li className="relative left-[18px] my-2">
          Explore scholarships that suit your academic interests and financial
          situation.
        </li>
        <li className="relative left-[18px] my-2">
          Let you know about any university programs that give scholarships to
          only our students.
        </li>
        <li className="relative left-[18px] my-2">
          Provide full up-to-date information on local and national
          scholarships.
        </li>
        <li className="relative left-[18px] my-2">
          Help draft scholarship application letters.
        </li>
        <li className="relative left-[18px] my-2">
          Present your scholarship request to the universities we partner with.
        </li>
      </ul>
      <p className="my-2 text-gray-500">
        If you require further information about how we assist in obtaining
        scholarships, and whom we work with, please feel free to call or send an
        e-mail to{" "}
        <a
          className="underline underline-offset-1"
          href="mailto:russellglobalconsultancy@gmail.com"
        >
          russellglobalconsultancy@gmail.com
        </a>
        . One of our friendly counselors will answer all your queries.
      </p>
    </div>
  );
};

export default ScholarshipAssistanceAccrodian;
