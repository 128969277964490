import React from "react";
import RecruitmentInquiryLayout from "../../components/Services/RecruitmentInquiryAccrodian/RecruitmentInquiryLayout";
import linksList from "../../utils/linksList";
import RecruitmentInquiryAccrodian from "../../components/Services/RecruitmentInquiryAccrodian/RecruitmentInquiryAccrodian";
import { Helmet } from "react-helmet";

const RecruitmentInquiry = () => {
  return (
    <div>
      <Helmet>
        <title>Russell Global Consultancy | Recruitment Inquiry</title>
        <meta
          name="description"
          content="Russell Global Consultancy Recruitment Inquiry Page"
        />
        <link
          rel="canonical"
          href="https://russellglobalconsultancy.com/services/recruitment-inquiry"
        />
      </Helmet>
      <RecruitmentInquiryLayout
        header={"RECRUITMENT INQUIRY"}
        subHeader={
          "Russell Global Consultancy is a leading foreign recruitment enterprise bringing top international skilled workers worldwide."
        }
        title={"RECRUITMENT INQUIRY"}
        subTitle1={"Book an appointment with one of our expert consultant!"}
        subTitle2={
          "Please provide the details with your preferred date and time of appointment, we will get back to you as soon as possible."
        }
        links={linksList}
      >
        <RecruitmentInquiryAccrodian />
      </RecruitmentInquiryLayout>
    </div>
  );
};

export default RecruitmentInquiry;
