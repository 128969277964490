import React from "react";
import PageLayout from "../../components/Common/PageLayout/PageLayout";
import linksList from "../../utils/linksList";
import ScholarshipAssistanceAccrodian from "../../components/Services/ScholarshipAssistanceAccrodian/ScholarshipAssistanceAccrodian";
import { Helmet } from "react-helmet";

const ScholarshipAssistance = () => {
  return (
    <div>
      <Helmet>
        <title>Russell Global Consultancy | Scholarship Assistance</title>
        <meta
          name="description"
          content="Russell Global Consultancy Scholarship Assistance Page"
        />
        <link
          rel="canonical"
          href="https://russellglobalconsultancy.com/services/study-scholarship-assistance"
        />
      </Helmet>
      <PageLayout
        header={"SCHOLARSHIP ASSISTANCE"}
        subHeader={"Are you looking for Scholarship?"}
        title={"Scholarship Assistance"}
        subTitle={
          "Are you a Bangladeshi students and looking for Bachelor, Masters and PhD Scholarships in UK, USA, Canada, Australia?"
        }
        links={linksList}
      >
        <ScholarshipAssistanceAccrodian />
      </PageLayout>
    </div>
  );
};

export default ScholarshipAssistance;
