import React, { useState } from "react";
import RecruitmentServiceAccrodianBody from "./RecruitmentServiceAccrodianBody";
import "./RecruitmentServiceAccrodian.css";
// 640px --> sm
// 768px --> md
// 1024px --> lg
// 1280px --> xl
// 1536px --> 2xl
const RecruitmentServiceAccrodian = () => {
  const [collapse1, setCollapse1] = useState(true);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      {/* 1 */}
      <h2
        id="accordion-collapse-heading-1"
        onClick={() => {
          const t = collapse1;
          setCollapse1(!collapse1);
          setCollapse2(false);
          setCollapse3(false);
          if (t) {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              el.style.height = "0px";
              el.style.transition = "height 0.5s ease-in-out";
            }
          } else {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "250px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "400px";
                el.style.transition = "height 0.5s ease-in-out";
              } else {
                el.style.height = "180px";
                el.style.transition = "height 0.5s ease-in-out";
              }
            }
            // 2
            const el2 = document?.getElementById("accordion-collapse-body-2");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
            // 3
            const el3 = document?.getElementById("accordion-collapse-body-3");
            if (el3) {
              el3.style.height = "0px";
              el3.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded="true"
          aria-controls="accordion-collapse-body-1"
        >
          <span
            className={`accrodian-body-title relative text-md md:text-xl font-semibold ${
              collapse1 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse1 ? "circle" : "circle-inactive"}`}
            ></span>
            Documents Required
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-1"
        className={`${
          collapse1 ? "" : ""
        } h-[400px] md:h-[180px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-1"
      >
        <RecruitmentServiceAccrodianBody>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">
              Power of Attorney in our favour, authorising us to conduct the
              recruitment on the employer’s behalf.
            </li>
            <li className="relative left-[18px] my-2">
              Demand letter stating the categories, quantities, salaries
              offered, and other terms & and conditions of employment.
            </li>
            <li className="relative left-[18px] my-2">
              Specimen of employment contract for each category to be recruited.
            </li>
            <li className="relative left-[18px] my-2">
              Copy of Approval Letter, issued by the Ministry of Labour, stating
              the nationalities and quantities to be recruited.
            </li>
          </ul>
        </RecruitmentServiceAccrodianBody>
      </div>
      {/* 2 */}
      <h2
        id="accordion-collapse-heading-2"
        onClick={() => {
          const t = collapse2;
          setCollapse2(!collapse2);
          setCollapse1(false);
          setCollapse3(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-2");
            if (el) {
              if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "600px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "1030px";
                el.style.transition = "height 0.5s ease-in-out";
              } else {
                el.style.height = "600px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-3");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-2");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-2"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-2"
        >
          <span
            className={`accrodian-body-title relative text-md md:text-xl font-semibold ${
              collapse2 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse2 ? "circle" : "circle-inactive"}`}
            ></span>
            Selection Procedure
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-2"
        className={`${collapse2 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-2"
      >
        <RecruitmentServiceAccrodianBody>
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
            <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
              <li className="relative left-[18px] my-2">
                Conduct a search from our database.
              </li>
              <li className="relative left-[18px] my-2">
                A conceptualisation of advertisements, selecting the right
                publication and releasing advertisements in the prescribed
                places.
              </li>
              <li className="relative left-[18px] my-2">
                Scrutiny of application, shortlisting, and preliminary
                interviews.
              </li>
              <li className="relative left-[18px] my-2">
                Telephone/distance screening if required.
              </li>
              <li className="relative left-[18px] my-2">
                Headhunting for senior positions if required.
              </li>
              <li className="relative left-[18px] my-2">Personal interview.</li>
              <li className="relative left-[18px] my-2">
                Practical and trade tests if required.
              </li>
              <li className="relative left-[18px] my-2">
                In-depth individual assessment, i.e. psychological, aptitude,
                and group behavioural tests.
              </li>
              <li className="relative left-[18px] my-2">Selection decision.</li>
              <li className="relative left-[18px] my-2">
                Taking the profiles of candidates from the present employer if
                required.
              </li>
              <li className="relative left-[18px] my-2">
                Checking the references and police clearance if required.
              </li>
              <li className="relative left-[18px] my-2">Employment offer.</li>
              <li className="relative left-[18px] my-2">
                Medical examination.
              </li>
              <li className="relative left-[18px] my-2">
                Employment contract signing.
              </li>
              <li className="relative left-[18px] my-2">
                Visa issuance of hired workers.
              </li>
              <li className="relative left-[18px] my-2">
                Arrival in a destined country from the respective country.
              </li>
            </ul>
          </div>
        </RecruitmentServiceAccrodianBody>
      </div>
      {/* 3 */}
      <h2
        id="accordion-collapse-heading-3"
        onClick={() => {
          const t = collapse3;
          setCollapse3(!collapse3);
          setCollapse1(false);
          setCollapse2(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-3");
            if (el) {
              el.style.height = "150px";
              el.style.transition = "height 0.5s ease-in-out";

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-3");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-3"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-3"
        >
          <span
            className={`accrodian-body-title relative text-md md:text-xl font-semibold ${
              collapse3 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse3 ? "circle" : "circle-inactive"}`}
            ></span>
            Financial Terms
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-3"
        className={`${collapse3 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-3"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">
              As per the policies of our company, we will charge you.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentServiceAccrodian;
