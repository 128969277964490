import React from "react";
import "./QuickLinks.css";
import { Link } from "react-router-dom";

const QuickLinks = ({ links }) => {
  return (
    <div className="quickLinks border-[3px] hidden lg:flex flex-col justify-center">
      <h2 className="text-center px-3 py-5 text-2xl font-bold bg-[#002147] text-white mb-2">
        Quick Links
      </h2>
      <div className="lists flex flex-col justify-center">
        {links?.map((l, i) => (
          <Link
            to={l?.target}
            key={i}
            className="list-container relative hover:bg-[#002147] p-0 flex items-center px-10 py-3"
          >
            <div className="list-disk me-4"></div>
            <div className="list py-[0.5rem] cursor-pointer text-[18px] font-semibold w-full text-gray-900">
              <span>{l?.title}</span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default QuickLinks;
