import React from "react";
import CustomIcon from "../../Common/CustomIcon/CustomIcon";
import { LuContact } from "react-icons/lu";
import { GrMapLocation } from "react-icons/gr";
import { AiOutlineHome } from "react-icons/ai";
import { MdDoneOutline } from "react-icons/md";
import CountUp from "react-countup";

const Count = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-5 bg-[#F4F4F4] px-6 md:px-12 lg:px-32 pb-28 py-6 mb-2">
      <div className="flex items-center">
        <div className="icon-container me-2">
          <CustomIcon className="count-icon">
            <LuContact />
          </CustomIcon>
        </div>
        <div className="vertical-line w-[1px] h-[40%] bg-gray-800 rounded-md me-2"></div>
        <div className="count-container ms-1">
          <div className="count-up-container-main text-4xl font-bold mb-2">
            <CountUp end={2} duration={5} />
            <span>+</span>
          </div>
          <div className="count-up-content">
            <span className="text-3xl font-thin text-blue-500">
              Years of Experience
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="icon-container me-2">
          <CustomIcon className="count-icon">
            <GrMapLocation />
          </CustomIcon>
        </div>
        <div className="vertical-line w-[1px] h-[40%] bg-gray-800 rounded-md me-2"></div>
        <div className="count-container ms-1">
          <div className="count-up-container-main text-4xl mb-2 font-bold">
            <CountUp end={5} duration={5} />
          </div>
          <div className="count-up-content">
            <span className="text-3xl font-thin text-blue-500">Country</span>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="icon-container me-2">
          <CustomIcon className="count-icon">
            <AiOutlineHome />
          </CustomIcon>
        </div>
        <div className="vertical-line w-[1px] h-[40%] bg-gray-800 rounded-md me-2"></div>
        <div className="count-container ms-1">
          <div className="count-up-container-main text-4xl mb-2 font-bold">
            <CountUp end={100} duration={5} />
            <span>+</span>
          </div>
          <div className="count-up-content">
            <span className="text-3xl font-thin text-blue-500">
              Universities
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="icon-container me-2">
          <CustomIcon className="count-icon">
            <MdDoneOutline />
          </CustomIcon>
        </div>
        <div className="vertical-line w-[1px] h-[40%] bg-gray-800 rounded-md me-2"></div>
        <div className="count-container ms-1">
          <div className="count-up-container-main text-4xl mb-2 font-bold">
            <CountUp end={500} duration={5} />
            <span>+</span>
          </div>
          <div className="count-up-content">
            <span className="text-3xl font-thin text-blue-500">
              Success Story
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Count;
