import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/Common/Footer/Footer";
import Header from "./components/Common/Header/Header";
import Home from "./pages/Home/Home";
import { createContext, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollToTop from "react-scroll-to-top";
import StudyInUk from "./pages/Services/StudyInUk";
import "animate.css";
import StudyInAustralia from "./pages/Services/StudyInAustralia";
import ScholarshipAssistance from "./pages/Services/ScholarshipAssistance";
import AboutUs from "./pages/Overviews/AboutUs";
import OurServices from "./pages/Overviews/OurServices";
import OurMission from "./pages/Overviews/OurMission";
import CeoMessage from "./pages/Overviews/CeoMessage";
import Contact from "./pages/Contact/Contact";
import RecruitmentProfessions from "./pages/Services/RecruitmentProfessions";
import RecruitmentServices from "./pages/Services/RecruitmentServices";
import { useEffect } from "react";
import StudyInNewZealand from "./pages/Services/StudyInNewZiland";
import StudyInEurope from "./pages/Services/StudyInEurope";
import StudyInChina from "./pages/Services/StudyInChina";
import RecruitmentInquiry from "./pages/Services/RecruitmentInquiry";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

export const menuContext = createContext({});
function App() {
  const [showNav, setShowNav] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [showDropSchoolership, setShowDropSchoolership] = useState(false);
  const [showDropUni, setShowDropUni] = useState(false);
  const handleBoth = () => {
    setShowNav(false);
    setShowDrop(false);
    setShowDropSchoolership(false);
    setShowDropUni(false);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
  return (
    <menuContext.Provider
      value={{
        showDrop,
        setShowDrop,
        showNav,
        setShowNav,
        showDropSchoolership,
        setShowDropSchoolership,
        showDropUni,
        setShowDropUni,
      }}
    >
      <div style={{ maxWidth: "100%", overflow: "hidden" }}>
        <Helmet>
          <title>Russell Global Consultancy</title>
          <meta name="description" content="Russell Global Consultancy" />
          <meta
            name="google-site-verification"
            content="82Mcbx0Hq4F7z-Df2VihTFjYFt_TaLUaEXASFIoyhT8"
          />
        </Helmet>
        <ScrollToTop
          smooth
          style={{
            backgroundColor: "#C5C5C5",
            height: "50px",
            width: "50px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            padding: "0.5rem",
            border: "3px solid #002147",
          }}
        />
        <Header
          showDrop={showDrop}
          showNav={showNav}
          setShowDrop={setShowDrop}
          showDropUni={showDropUni}
          showDropSchoolership={showDropSchoolership}
          setShowNav={setShowNav}
          setShowDropSchoolership={setShowDropSchoolership}
          setShowDropUni={setShowDropUni}
        />
        <div
          onClick={handleBoth}
          className={`${showNav ? "nav-overlay absolute block" : "static"}`}
        >
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/overview/about-us" element={<AboutUs />} />
            <Route path="/overview/our-services" element={<OurServices />} />
            <Route path="/overview/mission" element={<OurMission />} />
            <Route path="/overview/ceo-message" element={<CeoMessage />} />
            <Route
              path="/services/study-australia"
              element={<StudyInAustralia />}
            />
            <Route
              path="/services/study-newzealand"
              element={<StudyInNewZealand />}
            />
            <Route path="/services/study-uk" element={<StudyInUk />} />
            <Route path="/services/study-europe" element={<StudyInEurope />} />
            <Route path="/services/study-china" element={<StudyInChina />} />
            <Route
              path="/services/study-scholarship-assistance"
              element={<ScholarshipAssistance />}
            />
            <Route
              path="/services/recruitment-professions"
              element={<RecruitmentProfessions />}
            />
            <Route
              path="/services/recruitment-services"
              element={<RecruitmentServices />}
            />
            <Route
              path="/services/recruitment-inquiry"
              element={<RecruitmentInquiry />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsAndConditions />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </menuContext.Provider>
  );
}

export default App;
