import React, { useState } from "react";
import StudyInUkAccrodianBody from "./StudyInUkAccrodianBody";
import "./StudyUkAccrodian.css";

const StudyInUkAccrodian = () => {
  const [collapse1, setCollapse1] = useState(true);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [collapse4, setCollapse4] = useState(false);
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <h2
        id="accordion-collapse-heading-1"
        onClick={() => {
          const t = collapse1;
          setCollapse1(!collapse1);
          setCollapse2(false);
          setCollapse3(false);
          setCollapse4(false);
          if (t) {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              el.style.height = "0px";
              el.style.transition = "height 0.5s ease-in-out";
            }
          } else {
            const el = document?.getElementById("accordion-collapse-body-1");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "1390px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "1490px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "2380px";
                el.style.transition = "height 0.5s ease-in-out";
              } else {
                el.style.height = "1390px";
                el.style.transition = "height 0.5s ease-in-out";
              }
            }
            // 2
            const el2 = document?.getElementById("accordion-collapse-body-2");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
            // 3
            const el3 = document?.getElementById("accordion-collapse-body-3");
            if (el3) {
              el3.style.height = "0px";
              el3.style.transition = "height 0.5s ease-in-out";
            }
            // 4
            const el4 = document?.getElementById("accordion-collapse-body-4");
            if (el4) {
              el4.style.height = "0px";
              el4.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded="true"
          aria-controls="accordion-collapse-body-1"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse1 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse1 ? "circle" : "circle-inactive"}`}
            ></span>
            Why Study in UK?
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-1"
        className={`${
          collapse1 ? "" : ""
        } h-[2380px] md:h-[1490px] lg:h-[1390px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-1"
      >
        <StudyInUkAccrodianBody>
          <p className="my-2 text-gray-500 dark:text-gray-400">
            Studying in the United Kingdom (UK) is a popular choice for
            international students due to its wide range of advantages and
            opportunities. Here are some compelling reasons to consider studying
            in the UK:
          </p>
          <div className="bg-blue">
            <h5 className="accrodian-body-span my-6">World-Class Education:</h5>
            <p className="text-gray-500 mb-2">
              The UK is home to some of the world's most prestigious
              universities and educational institutions. Degrees earned in the
              UK are recognized and respected globally.
            </p>
          </div>
          <h5 className="accrodian-body-span">Diverse Range of Courses:</h5>
          <p className="text-gray-500 mb-2">
            UK universities offer a vast array of academic programs across
            various disciplines, ensuring there are options to suit virtually
            every interest and career aspiration.
          </p>
          <h5 className="accrodian-body-span">Research Opportunities: </h5>
          <p className="text-gray-500 mb-2">
            The UK is a global leader in research and innovation. Many
            universities have cutting-edge research facilities and provide
            opportunities for students to engage in groundbreaking research
            projects.
          </p>
          <h5 className="accrodian-body-span">
            Cultural and Historical Significance:
          </h5>
          <p className="text-gray-500 mb-2">
            The UK boasts a rich cultural and historical heritage. Studying
            there allows you to immerse yourself in a country steeped in
            history, art, literature, and culture.
          </p>
          <h5 className="accrodian-body-span">
            International Student Community:
          </h5>
          <p className="text-gray-500 mb-2">
            The UK is home to a diverse and multicultural international student
            community. You'll have the opportunity to interact with peers from
            all over the world, making lifelong friendships and expanding your
            global network.
          </p>
          <h5 className="accrodian-body-span">
            English Language Proficiency:{" "}
          </h5>
          <p className="text-gray-500 mb-2">
            English is the primary language of instruction in the UK, making it
            an ideal destination for students looking to improve their English
            language skills. This immersion helps enhance language proficiency.
          </p>
          <h5 className="accrodian-body-span">Global Business Hub: </h5>
          <p className="text-gray-500 mb-2">
            The UK is a major global business and financial center. Studying in
            the UK can open doors to valuable internship and job opportunities
            in various industries.
          </p>
          <h5 className="accrodian-body-span">Shorter Duration of Programs:</h5>
          <p className="text-gray-500 mb-2">
            Many undergraduate programs in the UK are three years in length,
            while postgraduate programs are typically one year. This can result
            in lower tuition fees and a quicker return on investment compared to
            longer programs in some other countries.
          </p>
          <h5 className="accrodian-body-span">
            Scholarships and Financial Support:{" "}
          </h5>
          <p className="text-gray-500 mb-2">
            UK universities offer a wide range of scholarships and financial aid
            options for international students, helping to make education more
            affordable.
          </p>
          <h5 className="accrodian-body-span">Healthcare Access:</h5>
          <p className="text-gray-500 mb-2">
            International students studying in the UK usually have access to the
            National Health Service (NHS), providing quality healthcare services
            during their stay.
          </p>
          <h5 className="accrodian-body-span">Travel Opportunities:</h5>
          <p className="text-gray-500 mb-2">
            The UK's central location in Europe makes it a convenient base for
            exploring other European countries, allowing students to travel and
            experience different cultures easily.
          </p>
          <h5 className="accrodian-body-span">Post-Study Work Options:</h5>
          <p className="text-gray-500 mb-2">
            The UK has introduced post-study work visas for international
            students, allowing them to stay and work in the UK after graduation,
            and gain valuable work experience.
          </p>
        </StudyInUkAccrodianBody>
      </div>
      <h2
        id="accordion-collapse-heading-2"
        onClick={() => {
          const t = collapse2;
          setCollapse2(!collapse2);
          setCollapse1(false);
          setCollapse3(false);
          setCollapse4(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-2");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "780px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "780px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "1380px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-3");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-4");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-2");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-2"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-2"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse2 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse2 ? "circle" : "circle-inactive"}`}
            ></span>
            Required Documents
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-2"
        className={`${collapse2 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-2"
      >
        <StudyInUkAccrodianBody>
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
            <h5 className="accrodian-body-span">
              Documents required for University Application:
            </h5>
            <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
              <li className="relative left-[18px] my-2">
                Scan copy of your passport
              </li>
              <li className="relative left-[18px] my-2">
                Scan copies (original) of all educational Certificates &
                Marksheets
              </li>
              <li className="relative left-[18px] my-2">
                Scan copies (original) of Two Academic Reference Letter (For PG)
              </li>
              <li className="relative left-[18px] my-2">
                Statement of Purpose
              </li>
              <li className="relative left-[18px] my-2">
                Scan copy (original) of UKVI/Academic IELTS Certificate
              </li>
              <li className="relative left-[18px] my-2">
                Job experience letter (if required)
              </li>
              <li className="relative left-[18px] my-2">
                Other supporting documents (if available, e.g extracurricular
                activities)
              </li>
            </ul>
            <h5 className="accrodian-body-span">
              Documents required for University Application:
            </h5>
            <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
              <li className="relative left-[18px] my-2">A valid Passport</li>
              <li className="relative left-[18px] my-2">
                A completed Online Visa Application Form
              </li>
              <li className="relative left-[18px] my-2">
                Appropriate Visa Application Fee
              </li>
              <li className="relative left-[18px] my-2">
                Immigration Health Surcharge (IHS)
              </li>
              <li className="relative left-[18px] my-2">
                Confirmation of Acceptance for Studies (CAS) Letter from your
                sponsor (University)
              </li>
              <li className="relative left-[18px] my-2">Medical Test - IOM</li>
              <li className="relative left-[18px] my-2">
                Bank Statement and Bank Solvency Certificate (from Listed Bank
                only)
              </li>
              <li className="relative left-[18px] my-2">
                All educational Certificates and Marksheets (Original)
              </li>
              <li className="relative left-[18px] my-2">
                IELTS/TOEFL Certificate
              </li>
              <li className="relative left-[18px] my-2">
                Job experience letter (if required)
              </li>
              <li className="relative left-[18px] my-2">
                Other supporting documents (if available, e.g extracurricular
                activities)
              </li>
            </ul>
          </div>
        </StudyInUkAccrodianBody>
      </div>
      <h2
        id="accordion-collapse-heading-3"
        onClick={() => {
          const t = collapse3;
          setCollapse3(!collapse3);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse4(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-3");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "655px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "730px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "1240px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-4");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-3");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-3"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-3"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse3 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse3 ? "circle" : "circle-inactive"}`}
            ></span>
            Application Procedures
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-3"
        className={`${collapse3 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-3"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <h5 className="accrodian-body-span">Intakes</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Most universities provide a September/October admission, while a
            select number also offer a January/February intake.
          </p>
          <h5 className="accrodian-body-span">English Language Requirements</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            IELTS from the UKVI is required for Foundation programs. For
            undergraduate programs, a UKVI/Academic IELTS score of 5.5 to 6.5 is
            necessary. A UKVI or Academic IELTS score of 6.0 to 6.5 is needed
            for postgraduate studies.
          </p>
          <h5 className="accrodian-body-span">Academic Requirement</h5>
          <h5 className="mb-2 font-semibold">Undergraduate – Bachelors</h5>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">
              GPA 4.75 to 5.0 in HSC / Min 2C in A Level to 3A* in A Level
            </li>
            <li className="relative left-[18px] my-2">
              Few Universities require Math course in 12th Grade for Business
              and Science programs
            </li>
          </ul>
          <h5 className="my-2 font-semibold">Postgraduate – Masters</h5>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">CGPA 2.50+</li>
            <li className="relative left-[18px] my-2">
              GRE or GMAT is not required for Business, Science and Engineering
              programs
            </li>
            <li className="relative left-[18px] my-2">
              For MBA programs at some Universities students need to have work
              experience along with GMAT Score
            </li>
          </ul>
          <p className="my-2 text-gray-500 dark:text-gray-400">
            You'll find that admittance standards differ amongst colleges
            because each school establishes its own. Normally, your application
            will be evaluated based on your present level of education.
          </p>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Contact the international office of the institution(s) you are
            applying to to confirm that you fulfill all admissions requirements
            for the course(s) you plan to apply for.
          </p>
        </div>
      </div>
      <h2
        id="accordion-collapse-heading-4"
        onClick={() => {
          const t = collapse4;
          setCollapse4(!collapse4);
          setCollapse1(false);
          setCollapse2(false);
          setCollapse3(false);
          if (!t) {
            const el = document?.getElementById("accordion-collapse-body-4");
            if (el) {
              if (window.innerWidth >= 1024) {
                el.style.height = "1300px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
                el.style.height = "1450px";
                el.style.transition = "height 0.5s ease-in-out";
              } else if (window.innerWidth < 768) {
                el.style.height = "2300px";
                el.style.transition = "height 0.5s ease-in-out";
              }

              // 1
              const el1 = document?.getElementById("accordion-collapse-body-1");
              if (el1) {
                el1.style.height = "0px";
                el1.style.transition = "height 0.5s ease-in-out";
              }
              // 3
              const el3 = document?.getElementById("accordion-collapse-body-2");
              if (el3) {
                el3.style.height = "0px";
                el3.style.transition = "height 0.5s ease-in-out";
              }
              // 4
              const el4 = document?.getElementById("accordion-collapse-body-3");
              if (el4) {
                el4.style.height = "0px";
                el4.style.transition = "height 0.5s ease-in-out";
              }
            }
          } else {
            const el2 = document?.getElementById("accordion-collapse-body-4");
            if (el2) {
              el2.style.height = "0px";
              el2.style.transition = "height 0.5s ease-in-out";
            }
          }
        }}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-collapse-body-4"
          aria-expanded="false"
          aria-controls="accordion-collapse-body-4"
        >
          <span
            className={`accrodian-body-title relative text-xl font-semibold ${
              collapse4 ? "text-[#ff683f]" : "text-[#002147]"
            } left-5`}
          >
            <span
              className={`${collapse4 ? "circle" : "circle-inactive"}`}
            ></span>
            Visa Procedure
          </span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-4"
        className={`${collapse4 ? "" : ""} h-[0px] overflow-hidden`}
        aria-labelledby="accordion-collapse-heading-4"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            The visa procedure for studying in the United Kingdom (UK) can vary
            depending on your nationality and the type of course you plan to
            pursue. However, here is a general overview of the student visa
            application process for international students:
          </p>
          <h5 className="accrodian-body-span">
            Choose Your Course and University:
          </h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Start by selecting a suitable course and educational institution in
            the UK. Ensure that your chosen institution is on the UK Home
            Office's list of recognized sponsors for international students.
          </p>
          <h5 className="accrodian-body-span">
            Receive a Confirmation of Acceptance for Studies (CAS):
          </h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Once you've been accepted into your chosen program, the university
            will issue a Confirmation of Acceptance for Studies (CAS). This is a
            critical document required for your visa application.
          </p>
          <h5 className="accrodian-body-span">Prepare Required Documents:</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Gather the necessary documents for your visa application, which may
            include:
          </p>
          <ul className="collapse-list overflow-x-hidden px-5 md:px-0">
            <li className="relative left-[18px] my-2">A valid passport.</li>
            <li className="relative left-[18px] my-2">
              CAS letter from your university.
            </li>
            <li className="relative left-[18px] my-2">
              Proof of English language proficiency (e.g., IELTS or TOEFL
              scores).
            </li>
            <li className="relative left-[18px] my-2">
              Proof of sufficient funds to cover tuition fees and living
              expenses.
            </li>
            <li className="relative left-[18px] my-2">
              Passport-sized photographs.
            </li>
            <li className="relative left-[18px] my-2">
              Tuberculosis (TB) test results (if applicable to your
              nationality).
            </li>
          </ul>
          <h5 className="accrodian-body-span">Online Visa Application:</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Create an account on the official UK government website for visa
            applications (UK Visas and Immigration - UKVI).
          </p>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Complete the online visa application form, pay the visa application
            fee, and book an appointment at a visa application center (VAC) in
            your country.
          </p>
          <h5 className="accrodian-body-span">Biometric Data Collection:</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Attend your appointment at the VAC to provide biometric data,
            including fingerprints and a photograph.
          </p>
          <h5 className="accrodian-body-span">
            Attend an Interview (if required):
          </h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Some applicants may be asked to attend an interview at the UK
            embassy or consulate in their home country.
          </p>
          <h5 className="accrodian-body-span">
            Pay the Immigration Health Surcharge (IHS):
          </h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Pay the Immigration Health Surcharge (IHS), which grants you access
            to the National Health Service (NHS) in the UK for healthcare
            services.
          </p>
          <h5 className="accrodian-body-span">Visa Decision:</h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            Once your visa application is processed, you will receive a decision
            on your visa application. This decision may be approved, refused, or
            subject to additional administrative processing. If your visa is
            approved, you will receive a vignette (sticker) in your passport
            that allows you to enter the UK. You must collect your full
            Biometric Residence Permit (BRP) within ten days of arriving in the
            UK.
          </p>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            The visa application process can be complex, so it's crucial to
            consult the official UK government website and contact your chosen
            educational institution for specific guidance. Additionally, it's
            advisable to begin the visa application process well in advance to
            ensure that your visa is ready before your intended course start
            date.
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudyInUkAccrodian;
